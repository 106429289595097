// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

// scss-docs-start gray-color-variables
$white: #fff !default;
$gray-base: #3c4b64 !default;
$gray-100: #ebedef !default;
$gray-200: #d8dbe0 !default;
$gray-300: #c4c9d0 !default;
$gray-400: #b1b7c1 !default;
$gray-500: #9da5b1 !default;
$gray-600: #8a93a2 !default;
$gray-700: #768192 !default;
$gray-800: #636f83 !default;
$gray-900: #4f5d73 !default;
$black: #000015 !default;
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900) !default;
// scss-docs-end gray-colors-map
// fusv-enable

$high-emphasis: rgba(shift-color($gray-base, +26), .95) !default;
$medium-emphasis: rgba(shift-color($gray-base, +26), .681) !default;
$disabled: rgba(shift-color($gray-base, +26), .38) !default;

$high-emphasis-inverse: rgba($white, .87) !default;
$medium-emphasis-inverse: rgba($white, .6) !default;
$disabled-inverse: rgba($white, .38) !default;

// scss-docs-start color-variables
$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #198754 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;
// scss-docs-end color-variables

// scss-docs-start colors-map
$colors: (
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "black": $black,
  "white": $white,
  "gray": $gray-600,
  "gray-dark": $gray-800) !default;
// scss-docs-end colors-map

// fusv-disable
$primary-dark: #1f1498 !default;
$primary-base: #321fdb !default;
$primary-50: #988fed !default;
$primary-25: #ccc7f6 !default;

$secondary-dark: #212233 !default;
$secondary-base: #9da5b1 !default;
$secondary-50: #9da5b1 !default;
$secondary-25: #ced2d8 !default;

$success-dark: #1b9e3e !default;
$success-base: #2eb85c !default;
$success-50: #96dbad !default;
$success-25: #cbedd6 !default;

$info-dark: #2982cc !default;
$info-base: #39f !default;
$info-50: #80c6ff !default;
$info-25: #c0e6ff !default;

$warning-dark: #f6960b !default;
$warning-base: #f9b115 !default;
$warning-50: #fcd88a !default;
$warning-25: #feecc5 !default;

$danger-dark: #d93737 !default;
$danger-base: #e55353 !default;
$danger-50: #f2a9a9 !default;
$danger-25: #f9d4d4 !default;

$light-dark: $gray-100 !default;
$light-base: $gray-100 !default;
$light-50: shift-color($light-base, -70) !default;
$light-25: shift-color($light-base, -80) !default;

$dark-dark: $gray-900 !default;
$dark-base: $gray-900 !default;
$dark-50: shift-color($dark-base, -70) !default;
$dark-25: shift-color($dark-base, -80) !default;
// fusv-enable

// scss-docs-start theme-color-variables
$primary: $primary-base !default;
$secondary: $secondary-base !default;
$success: $success-base !default;
$info: $info-base !default;
$warning: $warning-base !default;
$danger: $danger-base !default;
$light: $light-base !default;
$dark: $dark-base !default;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark) !default;
// scss-docs-end theme-colors-map

// Gradients

// scss-docs-start theme-gradients
$primary-gradient: (
  "start": #321fdb,
  "stop": #1f1498) !default;

$secondary-gradient: (
  "start": #c8d2dc,
  "stop": $white) !default;

$light-gradient: (
  "start": #e3e8ed,
  "stop": $white) !default;

$dark-gradient: (
  "start": #3c4b64,
  "stop": #212333) !default;

$danger-gradient: (
  "start": #e55353,
  "stop": #d93737) !default;

$warning-gradient: (
  "start": #f9b115,
  "stop": #f6960b) !default;

$success-gradient: (
  "start": #2eb85c,
  "stop": #1b9e3e) !default;

$info-gradient: (
  "start": #39f,
  "stop": #2982cc) !default;

$theme-gradients: (
  "primary": $primary-gradient,
  "secondary": $secondary-gradient,
  "success": $success-gradient,
  "info": $info-gradient,
  "warning": $warning-gradient,
  "danger": $danger-gradient,
  "light": $light-gradient,
  "dark": $dark-gradient) !default;
// scss-docs-end theme-gradients

// scss-docs-start theme-colors-rgb
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value") !default;
// scss-docs-end theme-colors-rgb

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 4.5 !default;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark: $high-emphasis-inverse !default;
$color-contrast-light: $high-emphasis !default;

// fusv-disable
$blue-100: tint-color($blue, 80%) !default;
$blue-200: tint-color($blue, 60%) !default;
$blue-300: tint-color($blue, 40%) !default;
$blue-400: tint-color($blue, 20%) !default;
$blue-500: $blue !default;
$blue-600: shade-color($blue, 20%) !default;
$blue-700: shade-color($blue, 40%) !default;
$blue-800: shade-color($blue, 60%) !default;
$blue-900: shade-color($blue, 80%) !default;

$indigo-100: tint-color($indigo, 80%) !default;
$indigo-200: tint-color($indigo, 60%) !default;
$indigo-300: tint-color($indigo, 40%) !default;
$indigo-400: tint-color($indigo, 20%) !default;
$indigo-500: $indigo !default;
$indigo-600: shade-color($indigo, 20%) !default;
$indigo-700: shade-color($indigo, 40%) !default;
$indigo-800: shade-color($indigo, 60%) !default;
$indigo-900: shade-color($indigo, 80%) !default;

$purple-100: tint-color($purple, 80%) !default;
$purple-200: tint-color($purple, 60%) !default;
$purple-300: tint-color($purple, 40%) !default;
$purple-400: tint-color($purple, 20%) !default;
$purple-500: $purple !default;
$purple-600: shade-color($purple, 20%) !default;
$purple-700: shade-color($purple, 40%) !default;
$purple-800: shade-color($purple, 60%) !default;
$purple-900: shade-color($purple, 80%) !default;

$pink-100: tint-color($pink, 80%) !default;
$pink-200: tint-color($pink, 60%) !default;
$pink-300: tint-color($pink, 40%) !default;
$pink-400: tint-color($pink, 20%) !default;
$pink-500: $pink !default;
$pink-600: shade-color($pink, 20%) !default;
$pink-700: shade-color($pink, 40%) !default;
$pink-800: shade-color($pink, 60%) !default;
$pink-900: shade-color($pink, 80%) !default;

$red-100: tint-color($red, 80%) !default;
$red-200: tint-color($red, 60%) !default;
$red-300: tint-color($red, 40%) !default;
$red-400: tint-color($red, 20%) !default;
$red-500: $red !default;
$red-600: shade-color($red, 20%) !default;
$red-700: shade-color($red, 40%) !default;
$red-800: shade-color($red, 60%) !default;
$red-900: shade-color($red, 80%) !default;

$orange-100: tint-color($orange, 80%) !default;
$orange-200: tint-color($orange, 60%) !default;
$orange-300: tint-color($orange, 40%) !default;
$orange-400: tint-color($orange, 20%) !default;
$orange-500: $orange !default;
$orange-600: shade-color($orange, 20%) !default;
$orange-700: shade-color($orange, 40%) !default;
$orange-800: shade-color($orange, 60%) !default;
$orange-900: shade-color($orange, 80%) !default;

$yellow-100: tint-color($yellow, 80%) !default;
$yellow-200: tint-color($yellow, 60%) !default;
$yellow-300: tint-color($yellow, 40%) !default;
$yellow-400: tint-color($yellow, 20%) !default;
$yellow-500: $yellow !default;
$yellow-600: shade-color($yellow, 20%) !default;
$yellow-700: shade-color($yellow, 40%) !default;
$yellow-800: shade-color($yellow, 60%) !default;
$yellow-900: shade-color($yellow, 80%) !default;

$green-100: tint-color($green, 80%) !default;
$green-200: tint-color($green, 60%) !default;
$green-300: tint-color($green, 40%) !default;
$green-400: tint-color($green, 20%) !default;
$green-500: $green !default;
$green-600: shade-color($green, 20%) !default;
$green-700: shade-color($green, 40%) !default;
$green-800: shade-color($green, 60%) !default;
$green-900: shade-color($green, 80%) !default;

$teal-100: tint-color($teal, 80%) !default;
$teal-200: tint-color($teal, 60%) !default;
$teal-300: tint-color($teal, 40%) !default;
$teal-400: tint-color($teal, 20%) !default;
$teal-500: $teal !default;
$teal-600: shade-color($teal, 20%) !default;
$teal-700: shade-color($teal, 40%) !default;
$teal-800: shade-color($teal, 60%) !default;
$teal-900: shade-color($teal, 80%) !default;

$cyan-100: tint-color($cyan, 80%) !default;
$cyan-200: tint-color($cyan, 60%) !default;
$cyan-300: tint-color($cyan, 40%) !default;
$cyan-400: tint-color($cyan, 20%) !default;
$cyan-500: $cyan !default;
$cyan-600: shade-color($cyan, 20%) !default;
$cyan-700: shade-color($cyan, 40%) !default;
$cyan-800: shade-color($cyan, 60%) !default;
$cyan-900: shade-color($cyan, 80%) !default;

$blues: (
  "blue-100": $blue-100,
  "blue-200": $blue-200,
  "blue-300": $blue-300,
  "blue-400": $blue-400,
  "blue-500": $blue-500,
  "blue-600": $blue-600,
  "blue-700": $blue-700,
  "blue-800": $blue-800,
  "blue-900": $blue-900) !default;

$indigos: (
  "indigo-100": $indigo-100,
  "indigo-200": $indigo-200,
  "indigo-300": $indigo-300,
  "indigo-400": $indigo-400,
  "indigo-500": $indigo-500,
  "indigo-600": $indigo-600,
  "indigo-700": $indigo-700,
  "indigo-800": $indigo-800,
  "indigo-900": $indigo-900) !default;

$purples: (
  "purple-100": $purple-100,
  "purple-200": $purple-200,
  "purple-300": $purple-300,
  "purple-400": $purple-400,
  "purple-500": $purple-500,
  "purple-600": $purple-600,
  "purple-700": $purple-700,
  "purple-800": $purple-800,
  "purple-900": $purple-900) !default;

$pinks: (
  "pink-100": $pink-100,
  "pink-200": $pink-200,
  "pink-300": $pink-300,
  "pink-400": $pink-400,
  "pink-500": $pink-500,
  "pink-600": $pink-600,
  "pink-700": $pink-700,
  "pink-800": $pink-800,
  "pink-900": $pink-900) !default;

$reds: (
  "red-100": $red-100,
  "red-200": $red-200,
  "red-300": $red-300,
  "red-400": $red-400,
  "red-500": $red-500,
  "red-600": $red-600,
  "red-700": $red-700,
  "red-800": $red-800,
  "red-900": $red-900) !default;

$oranges: (
  "orange-100": $orange-100,
  "orange-200": $orange-200,
  "orange-300": $orange-300,
  "orange-400": $orange-400,
  "orange-500": $orange-500,
  "orange-600": $orange-600,
  "orange-700": $orange-700,
  "orange-800": $orange-800,
  "orange-900": $orange-900) !default;

$yellows: (
  "yellow-100": $yellow-100,
  "yellow-200": $yellow-200,
  "yellow-300": $yellow-300,
  "yellow-400": $yellow-400,
  "yellow-500": $yellow-500,
  "yellow-600": $yellow-600,
  "yellow-700": $yellow-700,
  "yellow-800": $yellow-800,
  "yellow-900": $yellow-900) !default;

$greens: (
  "green-100": $green-100,
  "green-200": $green-200,
  "green-300": $green-300,
  "green-400": $green-400,
  "green-500": $green-500,
  "green-600": $green-600,
  "green-700": $green-700,
  "green-800": $green-800,
  "green-900": $green-900) !default;

$teals: (
  "teal-100": $teal-100,
  "teal-200": $teal-200,
  "teal-300": $teal-300,
  "teal-400": $teal-400,
  "teal-500": $teal-500,
  "teal-600": $teal-600,
  "teal-700": $teal-700,
  "teal-800": $teal-800,
  "teal-900": $teal-900) !default;

$cyans: (
  "cyan-100": $cyan-100,
  "cyan-200": $cyan-200,
  "cyan-300": $cyan-300,
  "cyan-400": $cyan-400,
  "cyan-500": $cyan-500,
  "cyan-600": $cyan-600,
  "cyan-700": $cyan-700,
  "cyan-800": $cyan-800,
  "cyan-900": $cyan-900) !default;
// fusv-enable

// scss-docs-start theme-color-variables
$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-900 !default;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark) !default;
// scss-docs-end theme-colors-map

// Characters which are escaped by the escape-svg function
$escaped-characters: (
  ("<", "%3c"),
  (">", "%3e"),
  ("#", "%23"),
  ("(", "%28"),
  (")", "%29"),
  ) !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-reduced-motion: true !default;
$enable-smooth-scroll: true !default;
$enable-grid-classes: true !default;
$enable-container-classes: true !default;
$enable-cssgrid: false !default;
$enable-button-pointers: true !default;
$enable-rfs: true !default;
$enable-validation-icons: true !default;
$enable-negative-margins: false !default;
$enable-deprecation-messages: true !default;
$enable-important-utilities: true !default;
$enable-elevations: true !default;
$enable-ltr: true !default;
$enable-rtl: true !default;

// Prefix for :root CSS variables

$variable-prefix: cui- !default; // Deprecated in v4.2.2 for the shorter `$prefix`
$prefix: $variable-prefix !default;

// Set mobile breakpoint

$mobile-breakpoint: md !default;

// Gradient
//
// The gradient which is added to components if `$enable-gradients` is `true`
// This gradient is also added to elements with `.bg-gradient`
// scss-docs-start variable-gradient
$gradient: linear-gradient(180deg, rgba($white, .15), rgba($white, 0)) !default;
// scss-docs-end variable-gradient

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// scss-docs-start spacer-variables-maps
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  ) !default;
// scss-docs-end spacer-variables-maps

// Position
//
// Define the edge positioning anchors of the position utilities.

// scss-docs-start position-map
$position-values: (
  0: 0,
  50: 50%,
  100: 100%) !default;
// scss-docs-end position-map

// Elevation
//
// Define common box shadows
$elevation-base-color: 60,
  75,
  100 !default; //#3c4b64

$elevations: (
  0: unquote("none"),
  1: unquote("0 1px 1px 0 rgba(var(--#{$prefix}elevation-base-color, #{$elevation-base-color}), .14), 0 2px 1px -1px rgba(var(--#{$prefix}elevation-base-color, #{$elevation-base-color}), .12), 0 1px 3px 0 rgba(var(--#{$prefix}elevation-base-color, #{$elevation-base-color}), .20)"),
  2: unquote("0 2px 2px 0 rgba(var(--#{$prefix}elevation-base-color, #{$elevation-base-color}), .14), 0 3px 1px -2px rgba(var(--#{$prefix}elevation-base-color, #{$elevation-base-color}), .12), 0 1px 5px 0 rgba(var(--#{$prefix}elevation-base-color, #{$elevation-base-color}), .20)"),
  3: unquote("0 3px 4px 0 rgba(var(--#{$prefix}elevation-base-color, #{$elevation-base-color}), .14), 0 3px 3px -2px rgba(var(--#{$prefix}elevation-base-color, #{$elevation-base-color}), .12), 0 1px 8px 0 rgba(var(--#{$prefix}elevation-base-color, #{$elevation-base-color}), .20)"),
  4: unquote("0 4px 5px 0 rgba(var(--#{$prefix}elevation-base-color, #{$elevation-base-color}), .14), 0 1px 10px 0 rgba(var(--#{$prefix}elevation-base-color, #{$elevation-base-color}), .12), 0 2px 4px -1px rgba(var(--#{$prefix}elevation-base-color, #{$elevation-base-color}), .20)"),
  6: unquote("0 6px 10px 0 rgba(var(--#{$prefix}elevation-base-color, #{$elevation-base-color}), .14), 0 1px 18px 0 rgba(var(--#{$prefix}elevation-base-color, #{$elevation-base-color}), .12), 0 3px 5px -1px rgba(var(--#{$prefix}elevation-base-color, #{$elevation-base-color}), .20)"),
  8: unquote("0 8px 10px 1px rgba(var(--#{$prefix}elevation-base-color, #{$elevation-base-color}), .14), 0 3px 14px 2px rgba(var(--#{$prefix}elevation-base-color, #{$elevation-base-color}), .12), 0 5px 5px -3px rgba(var(--#{$prefix}elevation-base-color, #{$elevation-base-color}), .20)"),
  9: unquote("0 9px 12px 1px rgba(var(--#{$prefix}elevation-base-color, #{$elevation-base-color}), .14), 0 3px 16px 2px rgba(var(--#{$prefix}elevation-base-color, #{$elevation-base-color}), .12), 0 5px 6px -3px rgba(var(--#{$prefix}elevation-base-color, #{$elevation-base-color}), .20)"),
  12: unquote("0 12px 17px 2px rgba(var(--#{$prefix}elevation-base-color, #{$elevation-base-color}), .14), 0 5px 22px 4px rgba(var(--#{$prefix}elevation-base-color, #{$elevation-base-color}), .12), 0 7px 8px -4px rgba(var(--#{$prefix}elevation-base-color, #{$elevation-base-color}), .20)"),
  16: unquote("0 16px 24px 2px rgba(var(--#{$prefix}elevation-base-color, #{$elevation-base-color}), .14), 0 6px 30px 5px rgba(var(--#{$prefix}elevation-base-color, #{$elevation-base-color}), .12), 0 8px 10px -5px rgba(var(--#{$prefix}elevation-base-color, #{$elevation-base-color}), .20)"),
  24: unquote("0 24px 38px 3px rgba(var(--#{$prefix}elevation-base-color, #{$elevation-base-color}), .14), 0 9px 46px 8px rgba(var(--#{$prefix}elevation-base-color, #{$elevation-base-color}), .12), 0 11px 15px -7px rgba(var(--#{$prefix}elevation-base-color, #{$elevation-base-color}), .20)")) !default;

// Body
//
// Settings for the `<body>` element.

$body-bg: $white !default;
$body-color: $high-emphasis !default;
$body-text-align: null !default;

// Links
//
// Style anchor elements.

$link-color: $primary !default;
$link-decoration: underline !default;
$link-shade-percentage: 20% !default;
$link-hover-color: shift-color($link-color, $link-shade-percentage) !default;
$link-hover-decoration: null !default;

$stretched-link-pseudo-element: after !default;
$stretched-link-z-index: 1 !default;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: 1rem !default;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px) !default;
// scss-docs-end grid-breakpoints

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints"
);
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px) !default;
// scss-docs-end container-max-widths

@include _assert-ascending($container-max-widths, "$container-max-widths"
);


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 1.5rem !default;
$grid-row-columns: 6 !default;

// Container padding

$container-padding-x: $grid-gutter-width !default;


// Components
//
// Define common padding and border radius sizes and more.

// scss-docs-start border-variables
$border-width: 1px !default;
$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px) !default;

$border-style: solid !default;
$border-color: $gray-200 !default;
$border-color-translucent: rgba($black, .175) !default;
// scss-docs-end border-variables

// scss-docs-start border-radius-variables
$border-radius: .375rem !default;
$border-radius-sm: .25rem !default;
$border-radius-lg: .5rem !default;
$border-radius-xl: 1rem !default;
$border-radius-2xl: 2rem !default;
$border-radius-pill: 50rem !default;
// scss-docs-end border-radius-variables

// scss-docs-start box-shadow-variables
$box-shadow: 0 .5rem 1rem rgba($black, .15) !default;
$box-shadow-sm: 0 .125rem .25rem rgba($black, .075) !default;
$box-shadow-lg: 0 1rem 3rem rgba($black, .175) !default;
$box-shadow-inset: inset 0 1px 2px rgba($black, .075) !default;
// scss-docs-end box-shadow-variables

$component-active-color: $high-emphasis-inverse !default;
$component-active-bg: $primary !default;

// scss-docs-start caret-variables
$caret-width: .3em !default;
$caret-vertical-align: $caret-width * .85 !default;
$caret-spacing: $caret-width * .85 !default;
// scss-docs-end caret-variables

$transition-base: all .2s ease-in-out !default;
$transition-fade: opacity .15s linear !default;
// scss-docs-start collapse-transition
$transition-collapse: height .35s ease !default;
$transition-collapse-width: width .35s ease !default;
// scss-docs-end collapse-transition

// stylelint-disable function-disallowed-list
// scss-docs-start aspect-ratios
$aspect-ratios: (
  "1x1": 100%,
  "4x3": calc(3 / 4 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%)) !default;
// scss-docs-end aspect-ratios
// stylelint-enable function-disallowed-list

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// scss-docs-start font-variables
// stylelint-disable value-keyword-case
$font-family-sans-serif: system-ui,
  -apple-system,
  "Segoe UI",
  Roboto,
  "Helvetica Neue",
  "Noto Sans",
  "Liberation Sans",
  Arial,
  sans-serif,
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
  "Noto Color Emoji" !default;
$font-family-monospace: SFMono-Regular,
  Menlo,
  Monaco,
  Consolas,
  "Liberation Mono",
  "Courier New",
  monospace !default;
// stylelint-enable value-keyword-case
$font-family-base: var(--#{$prefix}font-sans-serif) !default;
$font-family-code: var(--#{$prefix}font-monospace) !default;

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-root: null !default;
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * .875 !default;
$font-size-lg: $font-size-base * 1.25 !default;

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;

$font-weight-base: $font-weight-normal !default;

$line-height-base: 1.5 !default;
$line-height-sm: 1.25 !default;
$line-height-lg: 2 !default;

$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;
// scss-docs-end font-variables

// scss-docs-start font-sizes
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size) !default;
// scss-docs-end font-sizes

// scss-docs-start headings-variables
$headings-margin-bottom: $spacer * .5 !default;
$headings-font-family: null !default;
$headings-font-style: null !default;
$headings-font-weight: 500 !default;
$headings-line-height: 1.2 !default;
$headings-color: unset !default;
// scss-docs-end headings-variables

// scss-docs-start display-headings
$display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem) !default;

$display-font-weight: 300 !default;
$display-line-height: $headings-line-height !default;
// scss-docs-end display-headings

// scss-docs-start type-variables
$lead-font-size: $font-size-base * 1.25 !default;
$lead-font-weight: 300 !default;

$small-font-size: .875em !default;

$sub-sup-font-size: .75em !default;

$text-high-emphasis: $high-emphasis !default;
$text-medium-emphasis: $medium-emphasis !default;
$text-disabled: $disabled !default;
$text-muted: $disabled !default;

$text-high-emphasis-inverse: $high-emphasis-inverse !default;
$text-medium-emphasis-inverse: $medium-emphasis-inverse !default;
$text-disabled-inverse: $disabled-inverse !default;

$initialism-font-size: $small-font-size !default;

$blockquote-margin-y: $spacer !default;
$blockquote-font-size: $font-size-base * 1.25 !default;
$blockquote-footer-color: $gray-600 !default;
$blockquote-footer-font-size: $small-font-size !default;

$hr-margin-y: $spacer !default;
$hr-color: inherit !default;

// fusv-disable
$hr-bg-color: null !default; // Deprecated in v4.2.2
$hr-height: null !default; // Deprecated in v4.2.2
// fusv-enable

$hr-border-color: null !default; // Allows for inherited colors
$hr-border-width: $border-width !default;
$hr-opacity: .25 !default;

$legend-margin-bottom: .5rem !default;
$legend-font-size: 1.5rem !default;
$legend-font-weight: null !default;

$dt-font-weight: $font-weight-bold !default;

$list-inline-padding: .5rem !default;

$mark-padding: .1875em !default;
$mark-bg: $yellow-100 !default;
// scss-docs-end type-variables

// Icons
$icon-size-base: 1rem !default;
$icon-size-sm: $icon-size-base * .875 !default;
$icon-size-lg: $icon-size-base * 1.25 !default;
$icon-size-xl: $icon-size-base * 1.5 !default;
$icon-size-xxl: $icon-size-base * 2 !default;


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

// scss-docs-start table-variables
$table-cell-padding-y: .5rem !default;
$table-cell-padding-x: .5rem !default;
$table-cell-padding-y-sm: .25rem !default;
$table-cell-padding-x-sm: .25rem !default;

$table-cell-vertical-align: top !default;

$table-color: var(--#{$prefix}body-color) !default;
$table-bg: transparent !default;
$table-accent-bg: transparent !default;

$table-th-font-weight: 600 !default;

$table-striped-color: $table-color !default;
$table-striped-bg-factor: .05 !default;
$table-striped-bg: rgba($black, $table-striped-bg-factor) !default;

$table-active-color: $table-color !default;
$table-active-bg-factor: .1 !default;
$table-active-bg: rgba($black, $table-active-bg-factor) !default;

$table-hover-color: $table-color !default;
$table-hover-bg-factor: .075 !default;
$table-hover-bg: rgba($black, $table-hover-bg-factor) !default;

$table-border-factor: .1 !default;
$table-border-width: $border-width !default;
$table-border-color: var(--#{$prefix}border-color) !default;

$table-striped-order: odd !default;
$table-striped-columns-order: even !default;

$table-group-separator-color: currentColor !default;

$table-caption-color: $text-muted !default;

$table-bg-scale: -80% !default;
// scss-docs-end table-variables

// scss-docs-start table-loop
$table-variants: (
  "primary": shift-color($primary, $table-bg-scale),
  "secondary": shift-color($secondary, $table-bg-scale),
  "success": shift-color($success, $table-bg-scale),
  "info": shift-color($info, $table-bg-scale),
  "warning": shift-color($warning, $table-bg-scale),
  "danger": shift-color($danger, $table-bg-scale),
  "light": $light,
  "dark": $dark,
  ) !default;
// scss-docs-end table-loop


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

// scss-docs-start input-btn-variables
$input-btn-padding-y: .375rem !default;
$input-btn-padding-x: .75rem !default;
$input-btn-font-family: null !default;
$input-btn-font-size: $font-size-base !default;
$input-btn-line-height: $line-height-base !default;

$input-btn-focus-width: .25rem !default;
$input-btn-focus-color-opacity: .25 !default;
$input-btn-focus-color: rgba($component-active-bg, $input-btn-focus-color-opacity) !default;
$input-btn-focus-blur: 0 !default;
$input-btn-focus-box-shadow: 0 0 $input-btn-focus-blur $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-padding-y-sm: .25rem !default;
$input-btn-padding-x-sm: .5rem !default;
$input-btn-font-size-sm: $font-size-sm !default;

$input-btn-padding-y-lg: .5rem !default;
$input-btn-padding-x-lg: 1rem !default;
$input-btn-font-size-lg: $font-size-lg !default;

$input-btn-border-width: $border-width !default;
// scss-docs-end input-btn-variables


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

// scss-docs-start btn-variables
$btn-padding-y: $input-btn-padding-y !default;
$btn-padding-x: $input-btn-padding-x !default;
$btn-font-family: $input-btn-font-family !default;
$btn-font-size: $input-btn-font-size !default;
$btn-line-height: $input-btn-line-height !default;
$btn-white-space: null !default; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm: $input-btn-padding-y-sm !default;
$btn-padding-x-sm: $input-btn-padding-x-sm !default;
$btn-font-size-sm: $input-btn-font-size-sm !default;

$btn-padding-y-lg: $input-btn-padding-y-lg !default;
$btn-padding-x-lg: $input-btn-padding-x-lg !default;
$btn-font-size-lg: $input-btn-font-size-lg !default;

$btn-border-width: $input-btn-border-width !default;

$btn-font-weight: $font-weight-normal !default;
$btn-box-shadow: inset 0 1px 0 rgba($white, .15),
  0 1px 1px rgba($black, .075) !default;
$btn-focus-width: $input-btn-focus-width !default;
$btn-focus-box-shadow: $input-btn-focus-box-shadow !default;
$btn-disabled-opacity: .65 !default;
$btn-active-box-shadow: inset 0 3px 5px rgba($black, .125) !default;

$btn-link-color: var(--#{$prefix}link-color) !default;
$btn-link-hover-color: var(--#{$prefix}link-hover-color) !default;
$btn-link-disabled-color: $gray-600 !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius !default;
$btn-border-radius-sm: $border-radius-sm !default;
$btn-border-radius-lg: $border-radius-lg !default;

$btn-transition: color .15s ease-in-out,
  background-color .15s ease-in-out,
  border-color .15s ease-in-out,
  box-shadow .15s ease-in-out !default;

$btn-hover-bg-shade-amount: 15% !default;
$btn-hover-bg-tint-amount: 15% !default;
$btn-hover-border-shade-amount: 20% !default;
$btn-hover-border-tint-amount: 10% !default;
$btn-active-bg-shade-amount: 20% !default;
$btn-active-bg-tint-amount: 20% !default;
$btn-active-border-shade-amount: 25% !default;
$btn-active-border-tint-amount: 10% !default;

// scss-docs-start button-variants
$button-variants: (
  "primary": btn-color-map($primary, $primary),
  "secondary": btn-color-map($secondary, $secondary),
  "success": btn-color-map($success, $success),
  "danger": btn-color-map($danger, $danger),
  "warning": btn-color-map($warning, $warning),
  "info": btn-color-map($info, $info),
  "light": btn-color-map($light, $light),
  "dark": btn-color-map($dark, $dark)) !default;

$button-outline-ghost-variants: (
  "primary": btn-outline-color-map($primary),
  "secondary": btn-outline-color-map($secondary),
  "success": btn-outline-color-map($success),
  "danger": btn-outline-color-map($danger),
  "warning": btn-outline-color-map($warning),
  "info": btn-outline-color-map($info),
  "light": btn-outline-color-map($light),
  "dark": btn-outline-color-map($dark)) !default;
// scss-docs-end button-variants
// scss-docs-end btn-variables


// Forms
// scss-docs-start form-variables
// scss-docs-start form-text-variables
$form-text-margin-top: .25rem !default;
$form-text-font-size: $small-font-size !default;
$form-text-font-style: null !default;
$form-text-font-weight: null !default;
$form-text-color: $text-muted !default;
// scss-docs-end form-text-variables

// scss-docs-start form-label-variables
$form-label-margin-bottom: .5rem !default;
$form-label-font-size: null !default;
$form-label-font-style: null !default;
$form-label-font-weight: null !default;
$form-label-color: null !default;
// scss-docs-end form-label-variables

// scss-docs-start form-input-variables
$input-padding-y: $input-btn-padding-y !default;
$input-padding-x: $input-btn-padding-x !default;
$input-font-family: $input-btn-font-family !default;
$input-font-size: $input-btn-font-size !default;
$input-font-weight: $font-weight-base !default;
$input-line-height: $input-btn-line-height !default;

$input-padding-y-sm: $input-btn-padding-y-sm !default;
$input-padding-x-sm: $input-btn-padding-x-sm !default;
$input-font-size-sm: $input-btn-font-size-sm !default;

$input-padding-y-lg: $input-btn-padding-y-lg !default;
$input-padding-x-lg: $input-btn-padding-x-lg !default;
$input-font-size-lg: $input-btn-font-size-lg !default;

$input-bg: $body-bg !default;
$input-disabled-color: null !default;
$input-disabled-bg: $gray-200 !default;
$input-disabled-border-color: $gray-400 !default;

$input-color: $body-color !default;
$input-border-color: $gray-400 !default;
$input-border-width: $input-btn-border-width !default;
$input-box-shadow: $box-shadow-inset !default;

$input-border-radius: $border-radius !default;
$input-border-radius-sm: $border-radius-sm !default;
$input-border-radius-lg: $border-radius-lg !default;

$input-focus-bg: $input-bg !default;
$input-focus-border-color: tint-color($component-active-bg, 50%) !default;
$input-focus-color: $input-color !default;
$input-focus-width: $input-btn-focus-width !default;
$input-focus-box-shadow: $input-btn-focus-box-shadow !default;

$input-placeholder-color: $gray-600 !default;
$input-plaintext-color: $body-color !default;

$input-height-border: $input-border-width * 2 !default;

$input-height-inner: add($input-line-height * 1em, $input-padding-y * 2) !default;
$input-height-inner-half: add($input-line-height * .5em, $input-padding-y) !default;
$input-height-inner-quarter: add($input-line-height * .25em, $input-padding-y * .5) !default;

$input-height: add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false)) !default;
$input-height-sm: add($input-line-height * 1em, add($input-padding-y-sm * 2, $input-height-border, false)) !default;
$input-height-lg: add($input-line-height * 1em, add($input-padding-y-lg * 2, $input-height-border, false)) !default;

$input-transition: border-color .15s ease-in-out,
  box-shadow .15s ease-in-out !default;

$form-color-width: 3rem !default;
// scss-docs-end form-input-variables

// scss-docs-start form-check-variables
$form-check-input-width: 1em !default;
$form-check-min-height: $font-size-base * $line-height-base !default;
$form-check-padding-start: $form-check-input-width + .5em !default;
$form-check-margin-bottom: .125rem !default;
$form-check-label-color: unset !default;
$form-check-label-cursor: null !default;
$form-check-transition: null !default;

$form-check-input-active-filter: brightness(90%) !default;

$form-check-input-bg: $input-bg !default;
$form-check-input-border: 1px solid rgba($black, .25) !default;
$form-check-input-border-radius: .25em !default;
$form-check-radio-border-radius: 50% !default;
$form-check-input-focus-border: $input-focus-border-color !default;
$form-check-input-focus-box-shadow: $input-btn-focus-box-shadow !default;

$form-check-input-checked-color: $component-active-color !default;
$form-check-input-checked-bg-color: $component-active-bg !default;
$form-check-input-checked-border-color: $form-check-input-checked-bg-color !default;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/></svg>") !default;
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>") !default;

$form-check-input-indeterminate-color: $component-active-color !default;
$form-check-input-indeterminate-bg-color: $component-active-bg !default;
$form-check-input-indeterminate-border-color: $form-check-input-indeterminate-bg-color !default;
$form-check-input-indeterminate-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>") !default;

$form-check-input-disabled-opacity: .5 !default;
$form-check-label-disabled-opacity: $form-check-input-disabled-opacity !default;
$form-check-btn-check-disabled-opacity: $btn-disabled-opacity !default;

$form-check-inline-margin-end: 1rem !default;
// scss-docs-end form-check-variables

// scss-docs-start form-switch-variables
$form-switch-color: rgba($black, .25) !default;
$form-switch-width: 2em !default;
$form-switch-padding-start: $form-switch-width + .5em !default;
$form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}'/></svg>") !default;
$form-switch-border-radius: $form-switch-width !default;
$form-switch-transition: background-position .15s ease-in-out !default;

$form-switch-focus-color: $input-focus-border-color !default;
$form-switch-focus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color}'/></svg>") !default;

$form-switch-checked-color: $component-active-color !default;
$form-switch-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color}'/></svg>") !default;
$form-switch-checked-bg-position: right center !default;

$form-switch-widths: (
  lg: (width: 1.75em,
    height: 1.25em),
  xl: (width: 2em,
    height: 1.5em)) !default;
// scss-docs-end form-switch-variables

$form-check-inline-margin-end: 1rem !default;

// scss-docs-start input-group-variables
$input-group-addon-padding-y: $input-padding-y !default;
$input-group-addon-padding-x: $input-padding-x !default;
$input-group-addon-font-weight: $input-font-weight !default;
$input-group-addon-color: $input-color !default;
$input-group-addon-bg: $gray-200 !default;
$input-group-addon-border-color: $input-border-color !default;
// scss-docs-end input-group-variables

// scss-docs-start form-select-variables
$form-select-padding-y: $input-padding-y !default;
$form-select-padding-x: $input-padding-x !default;
$form-select-font-family: $input-font-family !default;
$form-select-font-size: $input-font-size !default;
$form-select-indicator-padding: $form-select-padding-x * 3 !default; // Extra padding for background-image
$form-select-font-weight: $input-font-weight !default;
$form-select-line-height: $input-line-height !default;
$form-select-color: $input-color !default;
$form-select-bg: $input-bg !default;
$form-select-disabled-color: null !default;
$form-select-disabled-bg: $gray-200 !default;
$form-select-disabled-border-color: $input-disabled-border-color !default;
$form-select-bg-position: right $form-select-padding-x center !default;
$form-select-bg-size: 16px 12px !default; // In pixels because image dimensions
$form-select-indicator-color: $gray-800 !default;
$form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg>") !default;

$form-select-feedback-icon-padding-end: $form-select-padding-x * 2.5 + $form-select-indicator-padding !default;
$form-select-feedback-icon-position: center right $form-select-indicator-padding !default;
$form-select-feedback-icon-size: $input-height-inner-half $input-height-inner-half !default;

$form-select-border-width: $input-border-width !default;
$form-select-border-color: $input-border-color !default;
$form-select-border-radius: $input-border-radius !default;
$form-select-box-shadow: $box-shadow-inset !default;

$form-select-focus-border-color: $input-focus-border-color !default;
$form-select-focus-width: $input-focus-width !default;
$form-select-focus-box-shadow: 0 0 0 $form-select-focus-width $input-btn-focus-color !default;

$form-select-padding-y-sm: $input-padding-y-sm !default;
$form-select-padding-x-sm: $input-padding-x-sm !default;
$form-select-font-size-sm: $input-font-size-sm !default;
$form-select-border-radius-sm: $input-border-radius-sm !default;

$form-select-padding-y-lg: $input-padding-y-lg !default;
$form-select-padding-x-lg: $input-padding-x-lg !default;
$form-select-font-size-lg: $input-font-size-lg !default;
$form-select-border-radius-lg: $input-border-radius-lg !default;

$form-select-transition: $input-transition !default;
// scss-docs-end form-select-variables

// scss-docs-start form-multi-select-variables
$form-multi-select-padding-y: $input-padding-y !default;
$form-multi-select-padding-x: $input-padding-x !default;
$form-multi-select-font-family: $input-font-family !default;
$form-multi-select-font-size: $input-font-size !default;
$form-multi-select-font-weight: $input-font-weight !default;
$form-multi-select-line-height: $input-line-height !default;
$form-multi-select-color: $input-color !default;
$form-multi-select-disabled-color: $gray-600 !default;
$form-multi-select-bg: $input-bg !default;

$form-multi-select-bg-position: right $form-multi-select-padding-x center !default;
$form-multi-select-bg-size: 16px 12px !default; // In pixels because image dimensions
$form-multi-select-disabled-bg: $gray-200 !default;
$form-multi-select-indicator-padding: $form-multi-select-padding-x * 3 !default; // Extra padding to account for the presence of the background-image based indicator
$form-multi-select-indicator-color: $gray-800 !default;
$form-multi-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-multi-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>") !default;

$form-multi-select-border-width: $input-border-width !default;
$form-multi-select-border-color: $input-border-color !default;
$form-multi-select-border-radius: $border-radius !default;
$form-multi-select-border-radius-sm: $border-radius-sm !default;
$form-multi-select-border-radius-lg: $border-radius-lg !default;
$form-multi-select-box-shadow: inset 0 1px 2px rgba($black, .075) !default;

$form-multi-select-focus-width: $input-focus-width !default;
$form-multi-select-focus-color: $input-color !default;
$form-multi-select-focus-bg: $input-bg !default;
$form-multi-select-focus-border-color: shift-color($component-active-bg, -25) !default;
$form-multi-select-focus-box-shadow: 0 0 0 $form-multi-select-focus-width $input-btn-focus-color !default;

$form-multi-select-selection-tags-padding-y: .125rem !default;
$form-multi-select-selection-tags-padding-x: .125rem !default;

$form-multi-select-tag-bg: $light !default;
$form-multi-select-tag-border-width: $border-width !default;
$form-multi-select-tag-border-color: $border-color !default;
$form-multi-select-tag-border-radius: .25rem !default;
$form-multi-select-tag-border-radius-sm: .125rem !default;
$form-multi-select-tag-border-radius-lg: .375rem !default;
$form-multi-select-tag-margin-y: .125rem !default;
$form-multi-select-tag-margin-x: .125rem !default;
$form-multi-select-tag-padding-y: subtract(($form-multi-select-padding-y - $form-multi-select-selection-tags-padding-y - $form-multi-select-tag-margin-y), $form-multi-select-tag-border-width) !default;
$form-multi-select-tag-padding-x: .5rem !default;

$form-multi-select-search-color: $input-color !default;
$form-multi-select-search-bg: $input-bg !default;
$form-multi-select-search-border-radius: $border-radius !default;

$form-multi-select-cleaner-width: .75rem !default;
$form-multi-select-cleaner-height: .75rem !default;
$form-multi-select-cleaner-padding-x: .75rem !default;
$form-multi-select-cleaner-padding-y: .5rem !default;
$form-multi-select-cleaner-color: $medium-emphasis !default;
$form-multi-select-cleaner-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$form-multi-select-cleaner-color}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>") !default;
$form-multi-select-cleaner-hover-color: $high-emphasis !default;
$form-multi-select-cleaner-hover-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$form-multi-select-cleaner-hover-color}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>") !default;
$form-multi-select-cleaner-focus-color: $high-emphasis !default;
$form-multi-select-cleaner-focus-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$form-multi-select-cleaner-focus-color}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>") !default;
$form-multi-select-cleaner-border-color: $input-border-color !default;

$form-multi-select-select-all-padding-y: .5rem !default;
$form-multi-select-select-all-padding-x: .75rem !default;
$form-multi-select-select-all-color: $medium-emphasis !default;
$form-multi-select-select-all-bg: transparent !default;
$form-multi-select-select-all-hover-color: $high-emphasis !default;
$form-multi-select-select-all-hover-bg: transparent !default;
$form-multi-select-select-all-border-width: $input-border-width !default;
$form-multi-select-select-all-border-color: $input-border-color !default;

$form-multi-select-options-padding-y: .5rem !default;
$form-multi-select-options-padding-x: .75rem !default;
$form-multi-select-options-margin-top: .625rem !default;
$form-multi-select-options-font-size: $font-size-base !default;
$form-multi-select-options-color: $body-color !default;
$form-multi-select-options-bg: $white !default;
$form-multi-select-options-border-color: $border-color !default;
$form-multi-select-options-border-width: $border-width !default;
$form-multi-select-options-border-radius: $border-radius !default;

$form-multi-select-optgroup-label-font-weight: $font-weight-bold !default;
$form-multi-select-optgroup-label-color: $disabled !default;

$form-multi-select-option-padding-y: .5rem !default;
$form-multi-select-option-padding-x: 1.25rem !default;
$form-multi-select-option-border-radius: $border-radius !default;

$form-multi-select-option-hover-color: shift-color($gray-900, 5) !default;
$form-multi-select-option-hover-bg: rgba($gray-100, .5) !default;

$form-multi-select-option-indicator-width: 1em !default;
$form-multi-select-option-indicator-bg: $form-check-input-bg !default;
$form-multi-select-option-indicator-border: $form-check-input-border !default;
$form-multi-select-option-indicator-border-radius: .25em !default;

$form-multi-select-option-selected-bg: $light !default;
$form-multi-select-option-selected-indicator-bg: $form-check-input-checked-bg-color !default;
$form-multi-select-option-selected-indicator-bg-image: $form-check-input-checked-bg-image !default;
$form-multi-select-option-selected-indicator-border-color: $form-multi-select-option-selected-indicator-bg !default;

$form-multi-select-option-disabled-color: $gray-600 !default;

$form-multi-select-padding-y-lg: $input-padding-y-lg !default;
$form-multi-select-padding-x-lg: $input-padding-x-lg !default;
$form-multi-select-font-size-lg: $input-font-size-lg !default;
$form-multi-select-selection-tags-padding-y-lg: .125rem !default;
$form-multi-select-selection-tags-padding-x-lg: .125rem !default;
$form-multi-select-tag-margin-y-lg: .125rem !default;
$form-multi-select-tag-margin-x-lg: .125rem !default;
$form-multi-select-tag-padding-y-lg: subtract(($form-multi-select-padding-y-lg - $form-multi-select-selection-tags-padding-y-lg - $form-multi-select-tag-margin-y-lg), $form-multi-select-tag-border-width) !default;
$form-multi-select-tag-padding-x-lg: .5rem !default;

$form-multi-select-padding-y-sm: $input-padding-y-sm !default;
$form-multi-select-padding-x-sm: $input-padding-x-sm !default;
$form-multi-select-font-size-sm: $input-font-size-sm !default;
$form-multi-select-selection-tags-padding-y-sm: .0675rem !default;
$form-multi-select-selection-tags-padding-x-sm: .125rem !default;
$form-multi-select-tag-margin-y-sm: .0675rem !default;
$form-multi-select-tag-margin-x-sm: .0675rem !default;
$form-multi-select-tag-padding-y-sm: 0 !default;
$form-multi-select-tag-padding-x-sm: .5rem !default;
// scss-docs-end form-multi-select-variables

// scss-docs-start form-range-variables
$form-range-track-width: 100% !default;
$form-range-track-height: .5rem !default;
$form-range-track-cursor: pointer !default;
$form-range-track-bg: $gray-300 !default;
$form-range-track-border-radius: 1rem !default;
$form-range-track-box-shadow: $box-shadow-inset !default;

$form-range-thumb-width: 1rem !default;
$form-range-thumb-height: $form-range-thumb-width !default;
$form-range-thumb-bg: $component-active-bg !default;
$form-range-thumb-border: 0 !default;
$form-range-thumb-border-radius: 1rem !default;
$form-range-thumb-box-shadow: 0 .1rem .25rem rgba($black, .1) !default;
$form-range-thumb-focus-box-shadow: 0 0 0 1px $body-bg,
  $input-focus-box-shadow !default;
$form-range-thumb-focus-box-shadow-width: $input-focus-width !default; // For focus box shadow issue in Edge
$form-range-thumb-active-bg: tint-color($component-active-bg, 70%) !default;
$form-range-thumb-disabled-bg: $gray-500 !default;
$form-range-thumb-transition: background-color .15s ease-in-out,
  border-color .15s ease-in-out,
  box-shadow .15s ease-in-out !default;
// scss-docs-end form-range-variables

// scss-docs-start form-file-variables
$form-file-button-color: $input-color !default;
$form-file-button-bg: $input-group-addon-bg !default;
$form-file-button-hover-bg: shade-color($form-file-button-bg, 5%) !default;
// scss-docs-end form-file-variables

// scss-docs-start form-floating-variables
$form-floating-height: add(3.5rem, $input-height-border) !default;
$form-floating-line-height: 1.25 !default;
$form-floating-padding-x: $input-padding-x !default;
$form-floating-padding-y: 1rem !default;
$form-floating-input-padding-t: 1.625rem !default;
$form-floating-input-padding-b: .625rem !default;
$form-floating-label-opacity: .65 !default;
$form-floating-label-transform: scale(.85) translateY(-.5rem) translateX(.15rem) !default;
$form-floating-transition: opacity .1s ease-in-out,
  transform .1s ease-in-out !default;
// scss-docs-end form-floating-variables

// Form validation

// scss-docs-start form-feedback-variables
$form-feedback-margin-top: $form-text-margin-top !default;
$form-feedback-font-size: $form-text-font-size !default;
$form-feedback-font-style: $form-text-font-style !default;
$form-feedback-valid-color: $success !default;
$form-feedback-invalid-color: $danger !default;

$form-feedback-icon-valid-color: $form-feedback-valid-color !default;
$form-feedback-icon-valid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>") !default;
$form-feedback-icon-invalid-color: $form-feedback-invalid-color !default;
$form-feedback-icon-invalid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>") !default;
// scss-docs-end form-feedback-variables

// scss-docs-start form-validation-states
$form-validation-states: (
  "valid": ("color": $form-feedback-valid-color,
    "icon": $form-feedback-icon-valid ),
  "invalid": ("color": $form-feedback-invalid-color,
    "icon": $form-feedback-icon-invalid )) !default;
// scss-docs-end form-validation-states
// scss-docs-end form-variables
// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

// scss-docs-start zindex-stack
$zindex-dropdown: 1000 !default;
$zindex-sticky: 1020 !default;
$zindex-fixed: 1030 !default;
$zindex-sidebar-backdrop: 1030 !default;
$zindex-offcanvas-backdrop: 1040 !default;
$zindex-offcanvas: 1045 !default;
$zindex-modal-backdrop: 1050 !default;
$zindex-modal: 1055 !default;
$zindex-popover: 1070 !default;
$zindex-tooltip: 1080 !default;
$zindex-toast: 1090 !default;
// scss-docs-end zindex-stack


// Navs
// scss-docs-start nav-variables
$nav-link-padding-y: .5rem !default;
$nav-link-padding-x: 1rem !default;
$nav-link-font-size: null !default;
$nav-link-font-weight: null !default;
$nav-link-color: var(--#{$prefix}link-color) !default;
$nav-link-hover-color: var(--#{$prefix}link-hover-color) !default;
$nav-link-transition: color .15s ease-in-out,
  background-color .15s ease-in-out,
  border-color .15s ease-in-out !default;
$nav-link-disabled-color: $gray-600 !default;

$nav-tabs-border-color: $gray-300 !default;
$nav-tabs-border-width: $border-width !default;
$nav-tabs-border-radius: $border-radius !default;
$nav-tabs-link-hover-border-color: $gray-200 $gray-200 $nav-tabs-border-color !default;
$nav-tabs-link-active-color: $gray-700 !default;
$nav-tabs-link-active-bg: $body-bg !default;
$nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg !default;

$nav-pills-border-radius: $border-radius !default;
$nav-pills-link-active-color: $component-active-color !default;
$nav-pills-link-active-bg: $component-active-bg !default;

$nav-underline-border-color: $gray-300 !default;
$nav-underline-border-width: $border-width * 2 !default;
$nav-underline-link-color: $gray-700 !default;
$nav-underline-link-active-color: $primary !default;
$nav-underline-link-active-border-color: $primary !default;
// scss-docs-end nav-variables


// Navbar

// scss-docs-start navbar-variables
$navbar-padding-y: $spacer * .5 !default;
$navbar-padding-x: null !default;

$navbar-nav-link-padding-x: .5rem !default;

$navbar-brand-font-size: $font-size-lg !default;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height: $font-size-base * $line-height-base + $nav-link-padding-y * 2 !default;
$navbar-brand-height: $navbar-brand-font-size * $line-height-base !default;
$navbar-brand-padding-y: (
  $nav-link-height - $navbar-brand-height) * .5 !default;
$navbar-brand-margin-end: 1rem !default;

$navbar-toggler-padding-y: .25rem !default;
$navbar-toggler-padding-x: .75rem !default;
$navbar-toggler-font-size: $font-size-lg !default;
$navbar-toggler-border-radius: $btn-border-radius !default;
$navbar-toggler-focus-width: $btn-focus-width !default;
$navbar-toggler-transition: box-shadow .15s ease-in-out !default;
// scss-docs-end navbar-variables

// scss-docs-start navbar-theme-variables
$navbar-dark-color: $medium-emphasis-inverse !default;
$navbar-dark-hover-color: $high-emphasis-inverse !default;
$navbar-dark-active-color: $high-emphasis-inverse !default;
$navbar-dark-disabled-color: $disabled-inverse !default;
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-dark-toggler-border-color: rgba($white, .1) !default;

$navbar-light-color: $medium-emphasis !default;
$navbar-light-hover-color: $high-emphasis !default;
$navbar-light-active-color: $high-emphasis !default;
$navbar-light-disabled-color: $disabled !default;
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-light-toggler-border-color: rgba($black, .1) !default;

$navbar-light-brand-color: $navbar-light-active-color !default;
$navbar-light-brand-hover-color: $navbar-light-active-color !default;
$navbar-dark-brand-color: $navbar-dark-active-color !default;
$navbar-dark-brand-hover-color: $navbar-dark-active-color !default;
// scss-docs-end navbar-theme-variables


// Dropdowns
//
// Dropdown menu container and contents.
// scss-docs-start dropdown-variables
$dropdown-min-width: 10rem !default;
$dropdown-padding-x: 0 !default;
$dropdown-padding-y: .5rem !default;
$dropdown-spacer: .125rem !default;
$dropdown-font-size: $font-size-base !default;
$dropdown-color: $body-color !default;
$dropdown-bg: $white !default;
$dropdown-border-color: var(--#{$prefix}border-color-translucent) !default;
$dropdown-border-radius: $border-radius !default;
$dropdown-border-width: $border-width !default;
$dropdown-inner-border-radius: subtract($dropdown-border-radius, $dropdown-border-width) !default;
$dropdown-divider-bg: $dropdown-border-color !default;
$dropdown-divider-margin-y: $spacer * .5 !default;
$dropdown-box-shadow: $box-shadow !default;

$dropdown-link-color: $gray-900 !default;
$dropdown-link-hover-color: shade-color($dropdown-link-color, 10%) !default;
$dropdown-link-hover-bg: $gray-200 !default;

$dropdown-link-active-color: $component-active-color !default;
$dropdown-link-active-bg: $component-active-bg !default;

$dropdown-link-disabled-color: $gray-500 !default;

$dropdown-item-padding-y: $spacer * .25 !default;
$dropdown-item-padding-x: $spacer !default;

$dropdown-header-color: $gray-600 !default;
$dropdown-header-padding-x: $dropdown-item-padding-x !default;
$dropdown-header-padding-y: $dropdown-padding-y !default;
// fusv-disable
$dropdown-header-padding: $dropdown-header-padding-y $dropdown-header-padding-x !default; // Deprecated in v4.2.2
// fusv-enable
// scss-docs-end dropdown-variables

// scss-docs-start dropdown-dark-variables
$dropdown-dark-color: $gray-300 !default;
$dropdown-dark-bg: $gray-800 !default;
$dropdown-dark-border-color: $dropdown-border-color !default;
$dropdown-dark-divider-bg: $dropdown-divider-bg !default;
$dropdown-dark-box-shadow: null !default;
$dropdown-dark-link-color: $dropdown-dark-color !default;
$dropdown-dark-link-hover-color: $high-emphasis-inverse !default;
$dropdown-dark-link-hover-bg: rgba($white, .15) !default;
$dropdown-dark-link-active-color: $dropdown-link-active-color !default;
$dropdown-dark-link-active-bg: $dropdown-link-active-bg !default;
$dropdown-dark-link-disabled-color: $gray-500 !default;
$dropdown-dark-header-color: $gray-500 !default;
// scss-docs-end dropdown-dark-variables


// Pagination

// scss-docs-start pagination-variables
$pagination-padding-y: .375rem !default;
$pagination-padding-x: .75rem !default;
$pagination-padding-y-sm: .25rem !default;
$pagination-padding-x-sm: .5rem !default;
$pagination-padding-y-lg: .75rem !default;
$pagination-padding-x-lg: 1.5rem !default;

$pagination-font-size: $font-size-base !default;

$pagination-color: var(--#{$prefix}link-color) !default;
$pagination-bg: $white !default;
$pagination-border-radius: $border-radius !default;
$pagination-border-width: $border-width !default;
$pagination-margin-start: calc($pagination-border-width * -1) !default; // stylelint-disable-line function-disallowed-list
$pagination-border-color: $gray-300 !default;

$pagination-focus-color: var(--#{$prefix}link-hover-color) !default;
$pagination-focus-bg: $gray-200 !default;
$pagination-focus-box-shadow: $input-btn-focus-box-shadow !default;
$pagination-focus-outline: 0 !default;

$pagination-hover-color: var(--#{$prefix}link-hover-color) !default;
$pagination-hover-bg: $gray-200 !default;
$pagination-hover-border-color: $gray-300 !default;

$pagination-active-color: $component-active-color !default;
$pagination-active-bg: $component-active-bg !default;
$pagination-active-border-color: $pagination-active-bg !default;

$pagination-disabled-color: $gray-600 !default;
$pagination-disabled-bg: $white !default;
$pagination-disabled-border-color: $gray-300 !default;

$pagination-transition: color .15s ease-in-out,
  background-color .15s ease-in-out,
  border-color .15s ease-in-out,
  box-shadow .15s ease-in-out !default;

$pagination-border-radius-sm: $border-radius-sm !default;
$pagination-border-radius-lg: $border-radius-lg !default;
// scss-docs-end pagination-variables


// Placeholders

// scss-docs-start placeholders
$placeholder-opacity-max: .5 !default;
$placeholder-opacity-min: .2 !default;
// scss-docs-end placeholders

// Cards
// scss-docs-start card-variables
$card-spacer-y: $spacer !default;
$card-spacer-x: $spacer !default;
$card-title-spacer-y: $spacer * .5 !default;
$card-border-width: $border-width !default;
$card-border-color: var(--#{$prefix}border-color-translucent) !default;
$card-border-radius: $border-radius !default;
$card-box-shadow: null !default;
$card-inner-border-radius: subtract($card-border-radius, $card-border-width) !default;
$card-cap-padding-y: $card-spacer-y * .5 !default;
$card-cap-padding-x: $card-spacer-x !default;
$card-cap-bg: rgba($black, .03) !default;
$card-cap-color: unset !default;
$card-height: null !default;
$card-color: unset !default;
$card-bg: $white !default;
$card-img-overlay-padding: $spacer !default;
$card-group-margin: $grid-gutter-width * .5 !default;
// scss-docs-end card-variables

// Accordion
// scss-docs-start accordion-variables
$accordion-padding-y: 1rem !default;
$accordion-padding-x: 1.25rem !default;
$accordion-color: var(--#{$prefix}body-color) !default;
$accordion-bg: $body-bg !default;
$accordion-border-width: $border-width !default;
$accordion-border-color: var(--#{$prefix}border-color) !default;
$accordion-border-radius: $border-radius !default;
$accordion-inner-border-radius: subtract($accordion-border-radius, $accordion-border-width) !default;

$accordion-body-padding-y: $accordion-padding-y !default;
$accordion-body-padding-x: $accordion-padding-x !default;

$accordion-button-padding-y: $accordion-padding-y !default;
$accordion-button-padding-x: $accordion-padding-x !default;
$accordion-button-color: $accordion-color !default;
$accordion-button-bg: var(--#{$prefix}accordion-bg) !default;
$accordion-transition: $btn-transition,
  border-radius .15s ease !default;
$accordion-button-active-bg: tint-color($component-active-bg, 90%) !default;
$accordion-button-active-color: shade-color($primary, 10%) !default;

$accordion-button-focus-border-color: $input-focus-border-color !default;
$accordion-button-focus-box-shadow: $btn-focus-box-shadow !default;

$accordion-icon-width: 1.25rem !default;
$accordion-icon-color: $accordion-button-color !default;
$accordion-icon-active-color: $accordion-button-active-color !default;
$accordion-icon-transition: transform .2s ease-in-out !default;
$accordion-icon-transform: rotate(-180deg) !default;

$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
// scss-docs-end accordion-variables

// Tooltips

// scss-docs-start tooltip-variables
$tooltip-font-size: $font-size-sm !default;
$tooltip-max-width: 200px !default;
$tooltip-color: $high-emphasis-inverse !default;
$tooltip-bg: $black !default;
$tooltip-border-radius: $border-radius !default;
$tooltip-opacity: .9 !default;
$tooltip-padding-y: $spacer * .25 !default;
$tooltip-padding-x: $spacer * .5 !default;
$tooltip-margin: null !default; // TODO: remove this in v6

$tooltip-arrow-width: .8rem !default;
$tooltip-arrow-height: .4rem !default;
// fusv-disable
$tooltip-arrow-color: null !default; // Deprecated in Bootstrap 5.2.0 for CSS variables
// fusv-enable
// scss-docs-end tooltip-variables

// Form tooltips must come after regular tooltips
// scss-docs-start tooltip-feedback-variables
$form-feedback-tooltip-padding-y: $tooltip-padding-y !default;
$form-feedback-tooltip-padding-x: $tooltip-padding-x !default;
$form-feedback-tooltip-font-size: $tooltip-font-size !default;
$form-feedback-tooltip-line-height: null !default;
$form-feedback-tooltip-opacity: $tooltip-opacity !default;
$form-feedback-tooltip-border-radius: $tooltip-border-radius !default;
// scss-docs-end tooltip-feedback-variables


// Popovers
// scss-docs-start popover-variables
$popover-font-size: $font-size-sm !default;
$popover-bg: $white !default;
$popover-max-width: 276px !default;
$popover-border-width: $border-width !default;
$popover-border-color: var(--#{$prefix}border-color-translucent) !default;
$popover-border-radius: $border-radius-lg !default;
$popover-inner-border-radius: subtract($popover-border-radius, $popover-border-width) !default;
$popover-box-shadow: $box-shadow !default;

$popover-header-font-size: $font-size-base !default;
$popover-header-bg: shade-color($popover-bg, 6%) !default;
$popover-header-color: var(--#{$prefix}heading-color) !default;
$popover-header-padding-y: .5rem !default;
$popover-header-padding-x: $spacer !default;

$popover-body-color: $body-color !default;
$popover-body-padding-y: $spacer !default;
$popover-body-padding-x: $spacer !default;

$popover-arrow-width: 1rem !default;
$popover-arrow-height: .5rem !default;
// scss-docs-end popover-variables

// fusv-disable
// Deprecated in Bootstrap 5.2.0 for CSS variables
$popover-arrow-color: $popover-bg !default;
$popover-arrow-outer-color: var(--#{$prefix}border-color-translucent) !default;
// fusv-enable


// Toasts
// scss-docs-start toast-variables
$toast-max-width: 350px !default;
$toast-padding-x: .75rem !default;
$toast-padding-y: .5rem !default;
$toast-font-size: .875rem !default;
$toast-color: unset !default;
$toast-background-color: rgba($white, .85) !default;
$toast-border-width: $border-width !default;
$toast-border-color: var(--#{$prefix}border-color-translucent) !default;
$toast-border-radius: $border-radius !default;
$toast-box-shadow: $box-shadow !default;
$toast-spacing: $container-padding-x !default;

$toast-header-color: $gray-600 !default;
$toast-header-background-color: rgba($white, .85) !default;
$toast-header-border-color: rgba($black, .05) !default;
// scss-docs-end toast-variables


// Badges
// scss-docs-start badge-variables
$badge-font-size: .75em !default;
$badge-font-weight: $font-weight-bold !default;
$badge-color: $high-emphasis-inverse !default;
$badge-padding-y: .35em !default;
$badge-padding-x: .65em !default;
$badge-border-radius: $border-radius !default;
// scss-docs-end badge-variables

$badge-font-size-sm: .65em !default;
$badge-padding-y-sm: .3em !default;
$badge-padding-x-sm: .5em !default;
// scss-docs-end badge-variables


// Modals

// scss-docs-start modal-variables
$modal-inner-padding: $spacer !default;

$modal-footer-margin-between: .5rem !default;

$modal-dialog-margin: .5rem !default;
$modal-dialog-margin-y-sm-up: 1.75rem !default;

$modal-title-line-height: $line-height-base !default;

$modal-content-color: unset !default;
$modal-content-bg: $white !default;
$modal-content-border-color: var(--#{$prefix}border-color-translucent) !default;
$modal-content-border-width: $border-width !default;
$modal-content-border-radius: $border-radius-lg !default;
$modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width) !default;
$modal-content-box-shadow-xs: $box-shadow-sm !default;
$modal-content-box-shadow-sm-up: $box-shadow !default;

$modal-backdrop-bg: $black !default;
$modal-backdrop-opacity: .5 !default;

$modal-header-border-color: var(--#{$prefix}border-color) !default;
$modal-header-border-width: $modal-content-border-width !default;
$modal-header-padding-y: $modal-inner-padding !default;
$modal-header-padding-x: $modal-inner-padding !default;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility

$modal-footer-bg: null !default;
$modal-footer-border-color: $modal-header-border-color !default;
$modal-footer-border-width: $modal-header-border-width !default;

$modal-sm: 300px !default;
$modal-md: 500px !default;
$modal-lg: 800px !default;
$modal-xl: 1140px !default;

$modal-fade-transform: translate(0, -50px) !default;
$modal-show-transform: none !default;
$modal-transition: transform .3s ease-out !default;
$modal-scale-transform: scale(1.02) !default;
// scss-docs-end modal-variables


// Avatars
// scss-docs-start avatar-variables
$avatar-width: 2rem !default;

$avatar-widths: (
  sm: 1.5rem,
  md: 2.5rem,
  lg: 3rem,
  xl: 4rem) !default;

$avatar-transition: margin .15s !default;
// scss-docs-end avatar-variables

// Alerts
//
// Define alert colors, border radius, and padding.

// scss-docs-start alert-variables
$alert-padding-y: $spacer !default;
$alert-padding-x: $spacer !default;
$alert-margin-bottom: 1rem !default;
$alert-border-radius: $border-radius !default;
$alert-link-font-weight: $font-weight-bold !default;
$alert-border-width: $border-width !default;
$alert-bg-scale: -80% !default;
$alert-border-scale: -70% !default;
$alert-color-scale: 40% !default;
$alert-dismissible-padding-r: $alert-padding-x * 3 !default; // 3x covers width of x plus default padding on either side
// scss-docs-end alert-variables

// scss-docs-start alert-variants
$alert-variants: (
  ) !default;

@each $state,
$value in $theme-colors {
  $map: (
);
$alert-variants: map-merge($alert-variants, map-merge($map, ($state: alert-color-map($value))));
}

// $alert-variants: (
//   "primary": alert-color-map($primary),
//   "secondary": alert-color-map($secondary),
//   "success": alert-color-map($success),
//   "danger": alert-color-map($danger),
//   "warning": alert-color-map($warning),
//   "info": alert-color-map($info),
//   "light": alert-color-map($light),
//   "dark": alert-color-map($dark)
// ) !default;
// scss-docs-end alert-variants

// Callouts
// scss-docs-start callout-variables
$callout-padding-y: $spacer !default;
$callout-padding-x: $spacer !default;
$callout-margin-y: $spacer !default;
$callout-margin-x: 0 !default;
$callout-border-radius: $border-radius !default;
$callout-border-width: $border-width !default;
$callout-border-color: $border-color !default;
$callout-border-left-width: (
  4 * $callout-border-width) !default;

$callout-variants: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "danger": $danger,
  "warning": $warning,
  "info": $info,
  "light": $light,
  "dark": $dark) !default;
// scss-docs-end callout-variables


// Progress bars

// scss-docs-start progress-variables
$progress-height: 1rem !default;
$progress-font-size: $font-size-base * .75 !default;
$progress-bg: $gray-200 !default;
$progress-border-radius: $border-radius !default;
$progress-box-shadow: $box-shadow-inset !default;
$progress-bar-color: $high-emphasis-inverse !default;
$progress-bar-bg: $primary !default;
$progress-bar-animation-timing: 1s linear infinite !default;
$progress-bar-transition: width .6s ease !default;

$progress-group-margin-bottom: $spacer !default;
$progress-group-header-margin-bottom: $spacer * .25 !default;
// scss-docs-end progress-variables

// List group
// scss-docs-start list-group-variables
$list-group-color: unset !default;
$list-group-bg: $white !default;
$list-group-border-color: rgba($black, .125) !default;
$list-group-border-width: $border-width !default;
$list-group-border-radius: $border-radius !default;

$list-group-item-padding-y: $spacer * .5 !default;
$list-group-item-padding-x: $spacer !default;
$list-group-item-bg-scale: -80% !default;
$list-group-item-color-scale: 40% !default;

$list-group-hover-bg: $gray-100 !default;
$list-group-active-color: $component-active-color !default;
$list-group-active-bg: $component-active-bg !default;
$list-group-active-border-color: $list-group-active-bg !default;

$list-group-disabled-color: $gray-600 !default;
$list-group-disabled-bg: $list-group-bg !default;

$list-group-action-color: $gray-700 !default;
$list-group-action-hover-color: $list-group-action-color !default;

$list-group-action-active-color: $body-color !default;
$list-group-action-active-bg: $gray-200 !default;
// scss-docs-end list-group-variables

$list-group-variants: (
  "primary": list-group-color-map($primary),
  "secondary": list-group-color-map($secondary),
  "success": list-group-color-map($success),
  "danger": list-group-color-map($danger),
  "warning": list-group-color-map($warning),
  "info": list-group-color-map($info),
  "light": list-group-color-map($light),
  "dark": list-group-color-map($dark)) !default;
// scss-docs-end list-group-variables


// Header
// scss-docs-start header-variables
$header-min-height: 4rem !default;
$header-padding-y: $spacer * .5 !default;
$header-padding-x: $spacer * .5 !default;
$header-brand-font-size: $font-size-lg !default;
$header-color: $medium-emphasis !default;
$header-bg: $white !default;
$header-border-color: $border-color !default;
$header-border-width: 1px !default;
$header-hover-color: $high-emphasis !default;
$header-active-color: $high-emphasis !default;
$header-disabled-color: $disabled !default;

// Compute the header-brand padding-y so the header-brand will have the same height as header-text and nav-link
$nav-link-height: $font-size-base * $line-height-base + $nav-link-padding-y * 2 !default;
$header-brand-height: $header-brand-font-size * $line-height-base !default;
$header-brand-padding-y: (
  $nav-link-height - $header-brand-height) * .5 !default;
$header-brand-margin-end: 1rem !default;
$header-brand-font-size: $font-size-lg !default;
$header-brand-color: $gray-900 !default;
$header-brand-hover-color: shade-color($gray-900, 10%) !default;

$header-toggler-padding-y: .25rem !default;
$header-toggler-padding-x: .75rem !default;
$header-toggler-font-size: $font-size-lg !default;
$header-toggler-color: $header-color !default;
$header-toggler-bg: transparent !default;
$header-toggler-border-radius: $btn-border-radius !default;
$header-toggler-hover-color: $header-active-color !default;

$header-toggler-icon-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$header-color}' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !default;
$header-toggler-hover-icon-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$header-hover-color}' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !default;

$header-nav-link-padding-x: .5rem !default;
$header-nav-link-padding-y: .5rem !default;

$header-divider-border-width: 1px !default;
$header-divider-border-color: $header-border-color !default;
// scss-docs-end header-variables

// Subheader
// scss-docs-start subheader-variables
$subheader-min-height: 3rem !default;
$subheader-padding-y: $spacer * .5 !default;
$subheader-padding-x: $spacer !default;
$subheader-border-color: $border-color !default;
$subheader-border-width: 1px !default;

$subheader-nav-link-padding-x: .5rem !default;
// scss-docs-end subheader-variables

// Default theme
// scss-docs-start subheader-default-themes
$subheader-bg: $white !default;
$subheader-color: $medium-emphasis !default;
$subheader-hover-color: $high-emphasis !default;
$subheader-active-color: $high-emphasis !default;
$subheader-disabled-color: $disabled !default;
// scss-docs-end subheader-default-themes


// Image thumbnails

// scss-docs-start thumbnail-variables
$thumbnail-padding: .25rem !default;
$thumbnail-bg: $body-bg !default;
$thumbnail-border-width: $border-width !default;
$thumbnail-border-color: var(--#{$prefix}border-color) !default;
$thumbnail-border-radius: $border-radius !default;
$thumbnail-box-shadow: $box-shadow-sm !default;
// scss-docs-end thumbnail-variables


// Figures

// scss-docs-start figure-variables
$figure-caption-font-size: $small-font-size !default;
$figure-caption-color: $gray-600 !default;
// scss-docs-end figure-variables


// Breadcrumbs
// scss-docs-start breadcrumb-variables
$breadcrumb-font-size: null !default;
$breadcrumb-padding-y: 0 !default;
$breadcrumb-padding-x: 0 !default;
$breadcrumb-item-padding-x: .5rem !default;
$breadcrumb-margin-bottom: 1rem !default;
$breadcrumb-bg: unset !default;
$breadcrumb-divider-color: $gray-600 !default;
$breadcrumb-active-color: $gray-600 !default;
$breadcrumb-divider: quote("/") !default;
$breadcrumb-divider-flipped: $breadcrumb-divider !default;
$breadcrumb-border-radius: null !default;
// scss-docs-end breadcrumb-variables

// Carousel
// scss-docs-start carousel-variables
$carousel-control-color: $high-emphasis-inverse !default;
$carousel-control-width: 15% !default;
$carousel-control-opacity: .5 !default;
$carousel-control-hover-opacity: .9 !default;
$carousel-control-transition: opacity .15s ease !default;

$carousel-indicator-width: 30px !default;
$carousel-indicator-height: 3px !default;
$carousel-indicator-hit-area-height: 10px !default;
$carousel-indicator-spacer: 3px !default;
$carousel-indicator-opacity: .5 !default;
$carousel-indicator-active-bg: $white !default;
$carousel-indicator-active-opacity: 1 !default;
$carousel-indicator-transition: opacity .6s ease !default;

$carousel-caption-width: 70% !default;
$carousel-caption-color: $high-emphasis-inverse !default;
$carousel-caption-padding-y: 1.25rem !default;
$carousel-caption-spacer: 1.25rem !default;

$carousel-control-icon-width: 2rem !default;

$carousel-control-prev-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>") !default;
$carousel-control-next-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>") !default;

$carousel-transition-duration: .6s !default;
$carousel-transition: transform $carousel-transition-duration ease-in-out !default; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)

$carousel-dark-indicator-active-bg: $black !default;
$carousel-dark-caption-color: $high-emphasis !default;
$carousel-dark-control-icon-filter: invert(1) grayscale(100) !default;
// scss-docs-end carousel-variables

// scss-docs-start sidebar-variables
$sidebar-width: 16rem !default;
$sidebar-widths: (
  sm: 12rem,
  lg: 20rem,
  xl: 24rem) !default;
$sidebar-padding-y: 0 !default;
$sidebar-padding-x: 0 !default;
$sidebar-color: $high-emphasis-inverse !default;
$sidebar-bg: $gray-base !default;
$sidebar-border-width: 0 !default;
$sidebar-border-color: transparent !default;
$sidebar-transition: margin-left .15s,
  margin-right .15s,
  box-shadow .075s,
  transform .15s,
  width .15s,
  z-index 0s ease .15s !default;

$sidebar-close-button-width: 2rem !default;
$sidebar-close-button-height: 4rem !default;

$sidebar-brand-height: 4rem !default;
$sidebar-brand-color: $high-emphasis-inverse !default;
$sidebar-brand-bg: rgba($black, .2) !default;

$sidebar-header-height: 4rem !default;
$sidebar-header-padding-y: .75rem !default;
$sidebar-header-padding-x: 1rem !default;
$sidebar-header-bg: rgba($black, .2) !default;
$sidebar-header-height-transition: height .15s,
  padding .15s !default;

$sidebar-narrow-width: 4rem !default;

$sidebar-backdrop-bg: $black !default;
$sidebar-backdrop-opacity: .5 !default;

$sidebar-nav-title-padding-y: .75rem !default;
$sidebar-nav-title-padding-x: 1rem !default;
$sidebar-nav-title-margin-top: 1rem !default;
$sidebar-nav-title-color: $medium-emphasis-inverse !default;
$sidebar-nav-title-transition: height .15s,
  margin .15s !default;

$sidebar-nav-link-padding-y: .8445rem !default;
$sidebar-nav-link-padding-x: 1rem !default;
$sidebar-nav-link-color: $medium-emphasis-inverse !default;
$sidebar-nav-link-bg: transparent !default;
$sidebar-nav-link-border-width: 0 !default;
$sidebar-nav-link-border-color: transparent !default;
$sidebar-nav-link-border-radius: 0 !default;
$sidebar-nav-link-transition: background .15s ease,
  color .15s ease !default;
$sidebar-nav-link-icon-color: $medium-emphasis-inverse !default;

$sidebar-nav-link-hover-color: $high-emphasis-inverse !default;
$sidebar-nav-link-hover-bg: rgba($white, .05) !default;
$sidebar-nav-link-hover-icon-color: $high-emphasis-inverse !default;

$sidebar-nav-link-active-color: $high-emphasis-inverse !default;
$sidebar-nav-link-active-bg: rgba($white, .05) !default;
$sidebar-nav-link-active-icon-color: $high-emphasis-inverse !default;

$sidebar-nav-link-disabled-color: $disabled-inverse !default;
$sidebar-nav-link-disabled-icon-color: $sidebar-nav-link-icon-color !default;

$sidebar-nav-icon-width: 4rem !default;
$sidebar-nav-icon-height: 1.25rem !default;
$sidebar-nav-icon-font-size: $sidebar-nav-icon-height !default;

$sidebar-nav-group-bg: rgba(0, 0, 0, .2) !default;
$sidebar-nav-group-transition: background .15s ease-in-out !default;
$sidebar-nav-group-toggle-show-color: $sidebar-nav-link-color !default;

$sidebar-nav-group-items-padding-y: 0 !default;
$sidebar-nav-group-items-padding-x: 0 !default;
$sidebar-nav-group-items-transition: height .15s ease !default;

$sidebar-nav-group-indicator-color: $medium-emphasis-inverse !default;
$sidebar-nav-group-indicator-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$sidebar-nav-group-indicator-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
$sidebar-nav-group-indicator-hover-color: $sidebar-nav-link-hover-color !default;
$sidebar-nav-group-indicator-hover-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$sidebar-nav-group-indicator-hover-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
$sidebar-nav-group-indicator-transition: transform .15s !default;

$sidebar-footer-height: auto !default;
$sidebar-footer-padding-y: .75rem !default;
$sidebar-footer-padding-x: 1rem !default;
$sidebar-footer-bg: rgba($black, .2) !default;
$sidebar-footer-height-transition: height .15s,
  padding .15s !default;

$sidebar-toggler-height: 3rem !default;
$sidebar-toggler-bg: rgba($black, .2) !default;
$sidebar-toggler-transition: transform .15s !default;

$sidebar-toggler-indicator-width: 4rem !default;
$sidebar-toggler-indicator-height: 3rem !default;
$sidebar-toggler-indicator-color: $gray-600 !default;
$sidebar-toggler-indicator-icon: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-toggler-indicator-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E") !default;
$sidebar-toggler-hover-bg: rgba(0, 0, 0, .3) !default;

$sidebar-toggler-indicator-hover-color: $sidebar-nav-link-hover-color !default;
$sidebar-toggler-indicator-hover-icon: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-toggler-indicator-hover-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E") !default;

$sidebar-light-color: $body-color !default;
$sidebar-light-bg: $white !default;
$sidebar-light-border-width: 0 !default;
$sidebar-light-border-color: transparent !default;

$sidebar-light-brand-color: $white !default;
$sidebar-light-brand-bg: $primary !default;

$sidebar-light-header-bg: rgba($black, .2) !default;

$sidebar-light-nav-title-color: $medium-emphasis !default;

$sidebar-light-nav-link-color: $medium-emphasis !default;
$sidebar-light-nav-link-bg: transparent !default;
$sidebar-light-nav-link-icon-color: $medium-emphasis !default;

$sidebar-light-nav-link-hover-color: $high-emphasis !default;
$sidebar-light-nav-link-hover-bg: theme-color("primary") !default;
$sidebar-light-nav-link-hover-icon-color: $high-emphasis !default;

$sidebar-light-nav-link-active-color: $high-emphasis !default;
$sidebar-light-nav-link-active-bg: rgba($white, .05) !default;
$sidebar-light-nav-link-active-icon-color: $high-emphasis !default;

$sidebar-light-nav-link-disabled-color: $disabled !default;
$sidebar-light-nav-link-disabled-icon-color: $sidebar-light-nav-link-icon-color !default;

$sidebar-light-nav-group-bg: rgba(0, 0, 0, .05) !default;
$sidebar-light-nav-group-toggle-show-color: $sidebar-light-nav-link-color !default;

$sidebar-light-nav-group-indicator-color: $medium-emphasis !default;
$sidebar-light-nav-group-indicator-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$sidebar-light-nav-group-indicator-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
$sidebar-light-nav-group-indicator-hover-color: $sidebar-light-nav-link-hover-color !default;
$sidebar-light-nav-group-indicator-hover-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$sidebar-light-nav-group-indicator-hover-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;

$sidebar-light-footer-bg: rgba($black, .1) !default;

$sidebar-light-toggler-bg: rgba($black, .1) !default;
$sidebar-light-toggler-hover-bg: rgba(0, 0, 0, .2) !default;
$sidebar-light-toggler-indicator-color: $medium-emphasis !default;
$sidebar-light-toggler-indicator-icon: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-light-toggler-indicator-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E") !default;
$sidebar-light-toggler-indicator-hover-color: $sidebar-light-nav-link-hover-color !default;
$sidebar-light-toggler-indicator-hover-icon: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-light-toggler-indicator-hover-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E") !default;
// scss-docs-end sidebar-variables

// Footer
// scss-docs-start footer-variables
$footer-min-height: 3rem !default;
$footer-padding-y: $spacer * .5 !default;
$footer-padding-x: $spacer !default;
$footer-bg: $gray-100 !default;
$footer-color: $body-color !default;
$footer-border-width: 1px !default;
$footer-border-color: $border-color !default;
// scss-docs-end footer-variables

// Spinners
// scss-docs-start spinner-variables
$spinner-width: 2rem !default;
$spinner-height: $spinner-width !default;
$spinner-vertical-align: -.125em !default;
$spinner-border-width: .25em !default;
$spinner-animation-speed: .75s !default;

$spinner-width-sm: 1rem !default;
$spinner-height-sm: $spinner-width-sm !default;
$spinner-border-width-sm: .2em !default;
// scss-docs-end spinner-variables


// Close
// scss-docs-start close-variables
$btn-close-width: 1em !default;
$btn-close-height: $btn-close-width !default;
$btn-close-padding-x: .25em !default;
$btn-close-padding-y: $btn-close-padding-x !default;
$btn-close-color: $high-emphasis !default;
$btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$btn-close-color}'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>") !default;
$btn-close-focus-shadow: $input-btn-focus-box-shadow !default;
$btn-close-opacity: .5 !default;
$btn-close-hover-opacity: .75 !default;
$btn-close-focus-opacity: 1 !default;
$btn-close-disabled-opacity: .25 !default;
$btn-close-white-filter: invert(1) grayscale(100%) brightness(200%) !default;
// scss-docs-end close-variables


// Offcanvas

// scss-docs-start offcanvas-variables
$offcanvas-padding-y: $modal-inner-padding !default;
$offcanvas-padding-x: $modal-inner-padding !default;
$offcanvas-horizontal-width: 400px !default;
$offcanvas-vertical-height: 30vh !default;
$offcanvas-transition-duration: .3s !default;
$offcanvas-border-color: $modal-content-border-color !default;
$offcanvas-border-width: $modal-content-border-width !default;
$offcanvas-title-line-height: $modal-title-line-height !default;
$offcanvas-bg-color: $modal-content-bg !default;
$offcanvas-color: $modal-content-color !default;
$offcanvas-box-shadow: $modal-content-box-shadow-xs !default;
$offcanvas-backdrop-bg: $modal-backdrop-bg !default;
$offcanvas-backdrop-opacity: $modal-backdrop-opacity !default;
// scss-docs-end offcanvas-variables

// Code

$code-font-size: $small-font-size !default;
$code-color: $pink !default;

$kbd-padding-y: .1875rem !default;
$kbd-padding-x: .375rem !default;
$kbd-font-size: $code-font-size !default;
$kbd-color: var(--#{$prefix}body-bg) !default;
$kbd-bg: var(--#{$prefix}body-color) !default;
$nested-kbd-font-weight: null !default; // Deprecated in v4.2.2, removing in v6

$pre-color: unset !default;

// Calendar & Date & Time Pickers

// Calendar
// scss-docs-start calendar-variables
$calendar-table-margin: .5rem !default;
$calendar-table-cell-size: 2.75rem !default;

$calendar-nav-padding: .5rem !default;
$calendar-nav-border-width: 1px !default;
$calendar-nav-border-color: $border-color !default;
$calendar-nav-date-color: $body-color !default;
$calendar-nav-date-hover-color: $primary !default;
$calendar-nav-icon-width: 1rem !default;
$calendar-nav-icon-height: 1rem !default;

$calendar-nav-icon-double-next-color: $gray-600 !default;
$calendar-nav-icon-double-next: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'><polygon fill='#{$calendar-nav-icon-double-next-color}' points='95.314 447.313 72.686 424.687 245.373 252 72.686 79.313 95.314 56.687 290.627 252 95.314 447.313'></polygon><polygon fill='#{$calendar-nav-icon-double-next-color}' points='255.314 447.313 232.686 424.687 405.373 252 232.686 79.313 255.314 56.687 450.627 252 255.314 447.313'></polygon></svg>") !default;
$calendar-nav-icon-double-next-hover-color: $body-color !default;
$calendar-nav-icon-double-next-hover: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'><polygon fill='#{$calendar-nav-icon-double-next-hover-color}' points='95.314 447.313 72.686 424.687 245.373 252 72.686 79.313 95.314 56.687 290.627 252 95.314 447.313'></polygon><polygon fill='#{$calendar-nav-icon-double-next-hover-color}' points='255.314 447.313 232.686 424.687 405.373 252 232.686 79.313 255.314 56.687 450.627 252 255.314 447.313'></polygon></svg>") !default;

$calendar-nav-icon-double-prev-color: $gray-600 !default;
$calendar-nav-icon-double-prev: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'><polygon fill='#{$calendar-nav-icon-double-prev-color}' points='416.686 447.313 221.373 252 416.686 56.687 439.314 79.313 266.627 252 439.314 424.687 416.686 447.313'></polygon><polygon fill='#{$calendar-nav-icon-double-prev-color}' points='256.686 447.313 61.373 252 256.686 56.687 279.314 79.313 106.627 252 279.314 424.687 256.686 447.313'></polygon></svg>") !default;
$calendar-nav-icon-double-prev-hover-color: $body-color !default;
$calendar-nav-icon-double-prev-hover: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'><polygon fill='#{$calendar-nav-icon-double-prev-hover-color}' points='416.686 447.313 221.373 252 416.686 56.687 439.314 79.313 266.627 252 439.314 424.687 416.686 447.313'></polygon><polygon fill='#{$calendar-nav-icon-double-prev-hover-color}' points='256.686 447.313 61.373 252 256.686 56.687 279.314 79.313 106.627 252 279.314 424.687 256.686 447.313'></polygon></svg>") !default;

$calendar-nav-icon-next-color: $gray-600 !default;
$calendar-nav-icon-next: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'><polygon fill='#{$calendar-nav-icon-next-color}' points='179.313 451.313 156.687 428.687 329.372 256 156.687 83.313 179.313 60.687 374.627 256 179.313 451.313'></polygon></svg>") !default;
$calendar-nav-icon-next-hover-color: $body-color !default;
$calendar-nav-icon-next-hover: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'><polygon fill='#{$calendar-nav-icon-next-hover-color}' points='179.313 451.313 156.687 428.687 329.372 256 156.687 83.313 179.313 60.687 374.627 256 179.313 451.313'></polygon></svg>") !default;

$calendar-nav-icon-prev-color: $gray-600 !default;
$calendar-nav-icon-prev: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'><polygon fill='#{$calendar-nav-icon-prev-color}' points='324.687 451.313 129.373 256 324.687 60.687 347.313 83.313 174.628 256 347.313 428.687 324.687 451.313'></polygon></svg>") !default;
$calendar-nav-icon-prev-hover-color: $body-color !default;
$calendar-nav-icon-prev-hover: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'><polygon fill='#{$calendar-nav-icon-prev-hover-color}' points='324.687 451.313 129.373 256 324.687 60.687 347.313 83.313 174.628 256 347.313 428.687 324.687 451.313'></polygon></svg>") !default;

$calendar-cell-header-inner-color: $text-medium-emphasis !default;

$calendar-cell-hover-bg: $gray-200 !default;
$calendar-cell-disabled-color: $text-disabled !default;

$calendar-cell-selected-color: $white !default;
$calendar-cell-selected-bg: $primary !default;

$calendar-cell-range-bg: rgba($primary, .125) !default;
$calendar-cell-range-hover-bg: rgba($primary, .25) !default;
$calendar-cell-range-hover-border-color: $primary !default;

$calendar-cell-today-color: $danger !default;
// scss-docs-end calendar-variables

// Picker
$picker-footer-border-width: 1px !default;
$picker-footer-border-color: $border-color !default;
$picker-footer-padding: .5rem !default;

// Date Picker
// scss-docs-start date-picker-variables
$date-picker-default-icon-color: $gray-600 !default;
$date-picker-default-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'><path fill='#{$date-picker-default-icon-color}' d='M472,96H384V40H352V96H160V40H128V96H40a24.028,24.028,0,0,0-24,24V456a24.028,24.028,0,0,0,24,24H472a24.028,24.028,0,0,0,24-24V120A24.028,24.028,0,0,0,472,96Zm-8,352H48V128h80v40h32V128H352v40h32V128h80Z'></path><rect width='32' height='32' x='112' y='224' fill='#{$date-picker-default-icon-color}'></rect><rect width='32' height='32' x='200' y='224' fill='#{$date-picker-default-icon-color}'></rect><rect width='32' height='32' x='280' y='224' fill='#{$date-picker-default-icon-color}'></rect><rect width='32' height='32' x='368' y='224' fill='#{$date-picker-default-icon-color}'></rect><rect width='32' height='32' x='112' y='296' fill='#{$date-picker-default-icon-color}'></rect><rect width='32' height='32' x='200' y='296' fill='#{$date-picker-default-icon-color}'></rect><rect width='32' height='32' x='280' y='296' fill='#{$date-picker-default-icon-color}'></rect><rect width='32' height='32' x='368' y='296' fill='#{$date-picker-default-icon-color}'></rect><rect width='32' height='32' x='112' y='368' fill='#{$date-picker-default-icon-color}'></rect><rect width='32' height='32' x='200' y='368' fill='#{$date-picker-default-icon-color}'></rect><rect width='32' height='32' x='280' y='368' fill='#{$date-picker-default-icon-color}'></rect><rect width='32' height='32' x='368' y='368' fill='#{$date-picker-default-icon-color}'></rect></svg>") !default;
$date-picker-invalid-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'><path fill='#{$form-feedback-invalid-color}' d='M472,96H384V40H352V96H160V40H128V96H40a24.028,24.028,0,0,0-24,24V456a24.028,24.028,0,0,0,24,24H472a24.028,24.028,0,0,0,24-24V120A24.028,24.028,0,0,0,472,96Zm-8,352H48V128h80v40h32V128H352v40h32V128h80Z'></path><rect width='32' height='32' x='112' y='224' fill='#{$form-feedback-invalid-color}'></rect><rect width='32' height='32' x='200' y='224' fill='#{$form-feedback-invalid-color}'></rect><rect width='32' height='32' x='280' y='224' fill='#{$form-feedback-invalid-color}'></rect><rect width='32' height='32' x='368' y='224' fill='#{$form-feedback-invalid-color}'></rect><rect width='32' height='32' x='112' y='296' fill='#{$form-feedback-invalid-color}'></rect><rect width='32' height='32' x='200' y='296' fill='#{$form-feedback-invalid-color}'></rect><rect width='32' height='32' x='280' y='296' fill='#{$form-feedback-invalid-color}'></rect><rect width='32' height='32' x='368' y='296' fill='#{$form-feedback-invalid-color}'></rect><rect width='32' height='32' x='112' y='368' fill='#{$form-feedback-invalid-color}'></rect><rect width='32' height='32' x='200' y='368' fill='#{$form-feedback-invalid-color}'></rect><rect width='32' height='32' x='280' y='368' fill='#{$form-feedback-invalid-color}'></rect><rect width='32' height='32' x='368' y='368' fill='#{$form-feedback-invalid-color}'></rect></svg>") !default;
$date-picker-valid-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'><path fill='#{$form-feedback-valid-color}' d='M472,96H384V40H352V96H160V40H128V96H40a24.028,24.028,0,0,0-24,24V456a24.028,24.028,0,0,0,24,24H472a24.028,24.028,0,0,0,24-24V120A24.028,24.028,0,0,0,472,96Zm-8,352H48V128h80v40h32V128H352v40h32V128h80Z'></path><rect width='32' height='32' x='112' y='224' fill='#{$form-feedback-valid-color}'></rect><rect width='32' height='32' x='200' y='224' fill='#{$form-feedback-valid-color}'></rect><rect width='32' height='32' x='280' y='224' fill='#{$form-feedback-valid-color}'></rect><rect width='32' height='32' x='368' y='224' fill='#{$form-feedback-valid-color}'></rect><rect width='32' height='32' x='112' y='296' fill='#{$form-feedback-valid-color}'></rect><rect width='32' height='32' x='200' y='296' fill='#{$form-feedback-valid-color}'></rect><rect width='32' height='32' x='280' y='296' fill='#{$form-feedback-valid-color}'></rect><rect width='32' height='32' x='368' y='296' fill='#{$form-feedback-valid-color}'></rect><rect width='32' height='32' x='112' y='368' fill='#{$form-feedback-valid-color}'></rect><rect width='32' height='32' x='200' y='368' fill='#{$form-feedback-valid-color}'></rect><rect width='32' height='32' x='280' y='368' fill='#{$form-feedback-valid-color}'></rect><rect width='32' height='32' x='368' y='368' fill='#{$form-feedback-valid-color}'></rect></svg>") !default;

$date-picker-cleaner-icon-color: $gray-600 !default;
$date-picker-cleaner-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'><polygon fill='#{$date-picker-cleaner-icon-color}' points='306.912 214.461 256 265.373 205.088 214.461 182.461 237.088 233.373 288 182.461 338.912 205.088 361.539 256 310.627 306.912 361.539 329.539 338.912 278.627 288 329.539 237.088 306.912 214.461'></polygon><path fill='#{$date-picker-cleaner-icon-color}' d='M472,96H384V40H352V96H160V40H128V96H40a24.028,24.028,0,0,0-24,24V456a24.028,24.028,0,0,0,24,24H472a24.028,24.028,0,0,0,24-24V120A24.028,24.028,0,0,0,472,96Zm-8,352H48V128h80v40h32V128H352v40h32V128h80Z'></path></svg>") !default;
$date-picker-cleaner-icon-hover-color: $body-color !default;
$date-picker-cleaner-icon-hover: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'><polygon fill='#{$date-picker-cleaner-icon-hover-color}' points='306.912 214.461 256 265.373 205.088 214.461 182.461 237.088 233.373 288 182.461 338.912 205.088 361.539 256 310.627 306.912 361.539 329.539 338.912 278.627 288 329.539 237.088 306.912 214.461'></polygon><path fill='#{$date-picker-cleaner-icon-hover-color})' d='M472,96H384V40H352V96H160V40H128V96H40a24.028,24.028,0,0,0-24,24V456a24.028,24.028,0,0,0,24,24H472a24.028,24.028,0,0,0,24-24V120A24.028,24.028,0,0,0,472,96Zm-8,352H48V128h80v40h32V128H352v40h32V128h80Z'></path></svg>") !default;

$date-picker-separator-icon-color: $gray-600 !default;
$date-picker-separator-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'><polygon fill='#{$date-picker-separator-icon-color}' points='359.873 121.377 337.246 144.004 433.243 240.001 16 240.001 16 240.002 16 272.001 16 272.002 433.24 272.002 337.246 367.996 359.873 390.623 494.498 256 359.873 121.377'></polygon></svg>") !default;
$date-picker-separator-icon-rtl: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'><polygon fill='#{$date-picker-separator-icon-color}' points='497.333 239.999 80.092 239.999 176.087 144.004 153.46 121.377 18.837 256 153.46 390.623 176.087 367.996 80.09 271.999 497.333 271.999 497.333 239.999'></polygon></svg>") !default;

$date-picker-ranges-width: 10rem !default;
$date-picker-ranges-padding: $spacer * .5 !default;
$date-picker-ranges-border-width: 1px !default;
$date-picker-ranges-border-color: $border-color !default;

$date-picker-timepicker-width: (
  7 * $calendar-table-cell-size) + (2 * $calendar-table-margin) !default;
$date-picker-timepicker-border-width: 1px !default;
$date-picker-timepicker-border-color: $border-color !default;
// scss-docs-end date-picker-variables

// Time Picker
// scss-docs-start time-picker-variables
$time-picker-default-icon-color: $gray-600 !default;
$time-picker-default-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'><polygon fill='#{$time-picker-default-icon-color}' points='271.514 95.5 239.514 95.5 239.514 273.611 355.127 328.559 368.864 299.657 271.514 253.389 271.514 95.5' class='ci-primary'></polygon><path fill='#{$time-picker-default-icon-color}' d='M256,16C123.452,16,16,123.452,16,256S123.452,496,256,496,496,388.548,496,256,388.548,16,256,16Zm0,448C141.125,464,48,370.875,48,256S141.125,48,256,48s208,93.125,208,208S370.875,464,256,464Z' class='ci-primary'></path></svg>") !default;
$time-picker-invalid-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'><polygon fill='#{$form-feedback-invalid-color}' points='271.514 95.5 239.514 95.5 239.514 273.611 355.127 328.559 368.864 299.657 271.514 253.389 271.514 95.5' class='ci-primary'></polygon><path fill='#{$form-feedback-invalid-color}' d='M256,16C123.452,16,16,123.452,16,256S123.452,496,256,496,496,388.548,496,256,388.548,16,256,16Zm0,448C141.125,464,48,370.875,48,256S141.125,48,256,48s208,93.125,208,208S370.875,464,256,464Z' class='ci-primary'></path></svg>") !default;
$time-picker-valid-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'><polygon fill='#{$form-feedback-valid-color}' points='271.514 95.5 239.514 95.5 239.514 273.611 355.127 328.559 368.864 299.657 271.514 253.389 271.514 95.5' class='ci-primary'></polygon><path fill='#{$form-feedback-valid-color}' d='M256,16C123.452,16,16,123.452,16,256S123.452,496,256,496,496,388.548,496,256,388.548,16,256,16Zm0,448C141.125,464,48,370.875,48,256S141.125,48,256,48s208,93.125,208,208S370.875,464,256,464Z' class='ci-primary'></path></svg>") !default;

$time-picker-cleaner-icon-color: $gray-600 !default;
$time-picker-cleaner-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'><polygon fill='#{$time-picker-cleaner-icon-color}' points='348.071 141.302 260.308 229.065 172.545 141.302 149.917 163.929 237.681 251.692 149.917 339.456 172.545 362.083 260.308 274.32 348.071 362.083 370.699 339.456 282.935 251.692 370.699 163.929 348.071 141.302' class='ci-primary'></polygon><path fill='#{$time-picker-cleaner-icon-color}' d='M425.706,86.294A240,240,0,0,0,86.294,425.706,240,240,0,0,0,425.706,86.294ZM256,464C141.309,464,48,370.691,48,256S141.309,48,256,48s208,93.309,208,208S370.691,464,256,464Z' class='ci-primary'></path></svg>") !default;
$time-picker-cleaner-icon-hover-color: $body-color !default;
$time-picker-cleaner-icon-hover: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' role='img'><polygon fill='#{$time-picker-cleaner-icon-hover-color}' points='348.071 141.302 260.308 229.065 172.545 141.302 149.917 163.929 237.681 251.692 149.917 339.456 172.545 362.083 260.308 274.32 348.071 362.083 370.699 339.456 282.935 251.692 370.699 163.929 348.071 141.302' class='ci-primary'></polygon><path fill='#{$time-picker-cleaner-icon-hover-color}' d='M425.706,86.294A240,240,0,0,0,86.294,425.706,240,240,0,0,0,425.706,86.294ZM256,464C141.309,464,48,370.691,48,256S141.309,48,256,48s208,93.309,208,208S370.691,464,256,464Z' class='ci-primary'></path></svg>") !default;

$time-picker-body-padding: $spacer * .5 !default;
$time-picker-roll-col-border-width: 1px !default;
$time-picker-roll-col-border-color: $border-color !default;
// scss-docs-end time-picker-variables