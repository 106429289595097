.sidebar {
  // scss-docs-start sidebar-css-vars
  --#{$prefix}sidebar-width: #{$sidebar-width};
  --#{$prefix}sidebar-bg: #{$sidebar-bg};
  --#{$prefix}sidebar-padding-x: #{$sidebar-padding-x};
  --#{$prefix}sidebar-padding-y: #{$sidebar-padding-y};
  --#{$prefix}sidebar-color: #{$sidebar-color};
  --#{$prefix}sidebar-border-width: #{$sidebar-border-width};
  --#{$prefix}sidebar-border-color: #{$sidebar-border-color};
  --#{$prefix}sidebar-brand-color: #{$sidebar-brand-color};
  --#{$prefix}sidebar-brand-height: #{$sidebar-brand-height};
  --#{$prefix}sidebar-brand-bg: #{$sidebar-brand-bg};
  --#{$prefix}sidebar-close-button-width: #{$sidebar-close-button-width};
  --#{$prefix}sidebar-close-button-height: #{$sidebar-close-button-height};
  --#{$prefix}sidebar-header-height: #{$sidebar-header-height};
  --#{$prefix}sidebar-header-bg: #{$sidebar-header-bg};
  --#{$prefix}sidebar-header-padding-x: #{$sidebar-header-padding-x};
  --#{$prefix}sidebar-header-padding-y: #{$sidebar-header-padding-y};
  --#{$prefix}sidebar-footer-bg: #{$sidebar-footer-bg};
  --#{$prefix}sidebar-footer-height: #{$sidebar-footer-height};
  --#{$prefix}sidebar-footer-padding-x: #{$sidebar-footer-padding-x};
  --#{$prefix}sidebar-footer-padding-y: #{$sidebar-footer-padding-y};
  --#{$prefix}sidebar-toggler-bg: #{$sidebar-toggler-bg};
  --#{$prefix}sidebar-toggler-height: #{$sidebar-toggler-height};
  --#{$prefix}sidebar-toggler-indicator: #{escape-svg($sidebar-toggler-indicator-icon)};
  --#{$prefix}sidebar-toggler-indicator-width: #{$sidebar-toggler-indicator-width};
  --#{$prefix}sidebar-toggler-indicator-height: #{$sidebar-toggler-indicator-height};
  --#{$prefix}sidebar-toggler-hover-bg: #{$sidebar-toggler-hover-bg};
  --#{$prefix}sidebar-toggler-indicator-hover: #{escape-svg($sidebar-toggler-indicator-hover-icon)};

  --#{$prefix}sidebar-narrow-width: #{$sidebar-narrow-width};

  --#{$prefix}sidebar-nav-title-padding-x: #{$sidebar-nav-title-padding-x};
  --#{$prefix}sidebar-nav-title-padding-y: #{$sidebar-nav-title-padding-y};
  --#{$prefix}sidebar-nav-title-margin-top: #{$sidebar-nav-title-margin-top};
  --#{$prefix}sidebar-nav-title-color: #{$sidebar-nav-title-color};
  --#{$prefix}sidebar-nav-link-padding-x: #{$sidebar-nav-link-padding-x};
  --#{$prefix}sidebar-nav-link-padding-y: #{$sidebar-nav-link-padding-y};
  --#{$prefix}sidebar-nav-link-color: #{$sidebar-nav-link-color};
  --#{$prefix}sidebar-nav-link-bg: #{$sidebar-nav-link-bg};
  --#{$prefix}sidebar-nav-link-border-color: #{$sidebar-nav-link-border-color};
  --#{$prefix}sidebar-nav-link-border: #{$sidebar-nav-link-border-width} solid var(--#{$prefix}sidebar-nav-link-border-color);
  --#{$prefix}sidebar-nav-link-border-radius: #{$sidebar-nav-link-border-radius};
  --#{$prefix}sidebar-nav-link-active-color: #{$sidebar-nav-link-active-color};
  --#{$prefix}sidebar-nav-link-active-bg: #{$sidebar-nav-link-active-bg};
  --#{$prefix}sidebar-nav-link-active-icon-color: #{$sidebar-nav-link-active-icon-color};
  --#{$prefix}sidebar-nav-link-disabled-color: #{$sidebar-nav-link-disabled-color};
  --#{$prefix}sidebar-nav-link-disabled-icon-color: #{$sidebar-nav-link-disabled-icon-color};
  --#{$prefix}sidebar-nav-link-hover-color: #{$sidebar-nav-link-hover-color};
  --#{$prefix}sidebar-nav-link-hover-bg: #{$sidebar-nav-link-hover-bg};
  --#{$prefix}sidebar-nav-link-hover-icon-color: #{$sidebar-nav-link-hover-icon-color};
  --#{$prefix}sidebar-nav-icon-width: #{$sidebar-nav-icon-width};
  --#{$prefix}sidebar-nav-icon-height: #{$sidebar-nav-icon-height};
  --#{$prefix}sidebar-nav-icon-font-size: #{$sidebar-nav-icon-font-size};
  --#{$prefix}sidebar-nav-link-icon-color: #{$sidebar-nav-link-icon-color};
  --#{$prefix}sidebar-nav-group-bg: #{$sidebar-nav-group-bg};
  --#{$prefix}sidebar-nav-group-items-padding-y: #{$sidebar-nav-group-items-padding-y};
  --#{$prefix}sidebar-nav-group-items-padding-x: #{$sidebar-nav-group-items-padding-x};
  --#{$prefix}sidebar-nav-group-indicator: #{escape-svg($sidebar-nav-group-indicator-icon)};
  --#{$prefix}sidebar-nav-group-indicator-hover: #{escape-svg($sidebar-nav-group-indicator-hover-icon)};
  --#{$prefix}sidebar-nav-group-toggle-show-color: #{$sidebar-nav-group-toggle-show-color};
  // scss-docs-end sidebar-css-vars

  position: relative;
  display: flex;
  // $sidebar-width is the width of the columns
  flex: 0 0 var(--#{$prefix}sidebar-width);
  flex-direction: column;
  // put the nav on the left
  order: -1;
  width: var(--#{$prefix}sidebar-width);
  padding: var(--#{$prefix}sidebar-padding-y) var(--#{$prefix}sidebar-padding-x);
  color: var(--#{$prefix}sidebar-color);
  background: var(--#{$prefix}sidebar-bg);
  @include ltr-rtl("border-right", var(--#{$prefix}sidebar-border-width) solid var(--#{$prefix}sidebar-border-color));
  box-shadow: none;
  @include transition($sidebar-transition);

  &:not(.sidebar-end){
    @include ltr-rtl("margin-left", 0);
    ~ * {
      --#{$prefix}sidebar-occupy-start: #{$sidebar-width};
    }
  }

  &.sidebar-end {
    order: 99;
    @include ltr-rtl("margin-right", 0);
    ~ * {
      --#{$prefix}sidebar-occupy-end: #{$sidebar-width};
    }
  }

  &[class*="bg-"]  {
    border-color: rgba($black, .1);
  }

  @each $width, $value in $sidebar-widths {
    &.sidebar-#{$width} {
      --#{$prefix}sidebar-width: #{$value};
      @include media-breakpoint-up($mobile-breakpoint) {
        &:not(.sidebar-end):not(.hide) {
          ~ * {
            --#{$prefix}sidebar-occupy-start: #{$value};
          }
        }
        &.sidebar-end:not(.hide) {
          ~ * {
            --#{$prefix}sidebar-occupy-end: #{$value};
          }
        }
      }
    }
  }

  @include media-breakpoint-up($mobile-breakpoint) {
    &.hide {
      &:not(.sidebar-end){
        @include ltr-rtl("margin-left", calc(-1 * var(--#{$prefix}sidebar-width)));
        ~ * {
          --#{$prefix}sidebar-occupy-start: 0;
        }
      }
      &.sidebar-end {
        @include ltr-rtl("margin-right", calc(-1 * var(--#{$prefix}sidebar-width)));
        ~ * {
          --#{$prefix}sidebar-occupy-end: 0;
        }
      }
    }
  }

  &.sidebar-fixed {
    @include media-breakpoint-up($mobile-breakpoint) {
      position: fixed;
      top: 0;
      bottom: 0;
      z-index: $zindex-fixed;
      @include elevation(2);

      &:not(.sidebar-end) {
        @include ltr-rtl("left", 0);
      }

      &.sidebar-end {
        @include ltr-rtl("right", 0);
      }
    }
  }

  &.sidebar-sticky {
    @include media-breakpoint-up($mobile-breakpoint) {
      position: sticky;
      top: 0;
      height: 100vh;
    }
  }

  &.sidebar-overlaid {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: $zindex-fixed + 2;

    &:not(.hide) {
      @include elevation(3);
    }

    &:not(.sidebar-end) {
      @include ltr-rtl("left", 0);
      ~ * {
        --#{$prefix}sidebar-occupy-start: 0 !important;  // stylelint-disable-line
      }
    }

    &.sidebar-end {
      @include ltr-rtl("right", 0);
      ~ * {
        --#{$prefix}sidebar-occupy-end: 0  !important; // stylelint-disable-line
      }
    }
  }

  @include media-breakpoint-down($mobile-breakpoint) {
    // Some of our components use this property to detect if the sidebar has mobile behavior.
    --#{$prefix}is-mobile: true;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: $zindex-fixed + 1;

    &:not(.sidebar-end) {
      @include ltr-rtl("left", 0);
      ~ * {
        --#{$prefix}sidebar-occupy-start: 0 !important; // stylelint-disable-line
      }

      &:not(.show) {
        @include ltr-rtl("margin-left", calc(-1 * var(--#{$prefix}sidebar-width)));
      }
    }
    &.sidebar-end {
      @include ltr-rtl("right", 0);
      ~ * {
        --#{$prefix}sidebar-occupy-end: 0 !important; // stylelint-disable-line
      }

      &:not(.show) {
        @include ltr-rtl("margin-right", calc(-1 * var(--#{$prefix}sidebar-width)));
      }
    }
  }
}

.sidebar-close {
  position: absolute;
  top: 0;
  @include ltr-rtl("right", 0);
  width: var(--#{$prefix}sidebar-close-button-width);
  height: var(--#{$prefix}sidebar-close-button-height);
  color: var(--#{$prefix}sidebar-color);
  background: transparent;
  border: 0;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: 0;
  }
}

.sidebar-brand {
  display: flex;
  flex: 0 0 var(--#{$prefix}sidebar-brand-height);
  align-items: center;
  justify-content: center;
  color: var(--#{$prefix}sidebar-brand-color);
  background: var(--#{$prefix}sidebar-brand-bg);

  .sidebar-brand-narrow {
    display: none;
  }
}

.sidebar-header {
  flex: 0 0 var(--#{$prefix}sidebar-header-height);
  padding: var(--#{$prefix}sidebar-header-padding-y) var(--#{$prefix}sidebar-header-padding-x);
  text-align: center;
  background: var(--#{$prefix}sidebar-header-bg);
  @include transition($sidebar-header-height-transition);

  .nav-link {
    display: flex;
    align-items: center;
    min-height: var(--#{$prefix}sidebar-header-height);
  }
}

.sidebar-footer {
  flex: 0 0 var(--#{$prefix}sidebar-footer-height);
  padding: var(--#{$prefix}sidebar-footer-padding-y) var(--#{$prefix}sidebar-footer-padding-x);
  background: var(--#{$prefix}sidebar-footer-bg);
  @include transition($sidebar-footer-height-transition);
}

.sidebar-toggler {
  display: flex;
  flex: 0 0 var(--#{$prefix}sidebar-toggler-height);
  justify-content: flex-end;
  width: inherit;
  padding: 0;
  cursor: pointer;
  background-color: var(--#{$prefix}sidebar-toggler-bg);
  border: 0;

  @include media-breakpoint-down($mobile-breakpoint) {
    display: none;
  }

  &::before {
    display: block;
    width: var(--#{$prefix}sidebar-toggler-indicator-width);
    height: var(--#{$prefix}sidebar-toggler-indicator-height);
    content: "";
    background-image: var(--#{$prefix}sidebar-toggler-indicator);
    background-repeat: no-repeat;
    background-position: center;
    background-size: calc(var(--#{$prefix}sidebar-toggler-indicator-height) * .25); // stylelint-disable-line function-disallowed-list
    @include transition($sidebar-toggler-transition);
    @include rtl() {
      transform: rotate(-180deg);
    }
  }

  &:focus {
    outline: 0;
  }

  &:hover {
    background-color: var(--#{$prefix}sidebar-toggler-hover-bg);
    &::before {
      background-image: var(--#{$prefix}sidebar-toggler-indicator-hover);
    }
  }

  .sidebar-end & {
    justify-content: flex-start;
    &::before {
      transform: rotate(-180deg);
    }
  }
}

// Backdrop background

.sidebar-backdrop {
  // scss-docs-start sidebar-backdrop-css-vars
  --#{$prefix}backdrop-zindex: #{$zindex-sidebar-backdrop};
  --#{$prefix}backdrop-bg: #{$sidebar-backdrop-bg};
  --#{$prefix}backdrop-opacity: #{$sidebar-backdrop-opacity};
  // scss-docs-end sidebar-backdrop-css-vars

  @include media-breakpoint-down($mobile-breakpoint) {
    @include overlay-backdrop(var(--#{$prefix}backdrop-zindex), var(--#{$prefix}backdrop-bg), var(--#{$prefix}backdrop-opacity));
  }
}
//
// Sidebar themes
//

.sidebar-light {
  --#{$prefix}sidebar-color: #{$sidebar-light-color};
  --#{$prefix}sidebar-bg: #{$sidebar-light-bg};
  --#{$prefix}sidebar-border-width: #{$sidebar-light-border-width};
  --#{$prefix}sidebar-border-color: #{$sidebar-light-border-color};
  --#{$prefix}sidebar-brand-color: #{$sidebar-light-brand-color};
  --#{$prefix}sidebar-brand-bg: #{$sidebar-light-brand-bg};
  --#{$prefix}sidebar-header-bg: #{$sidebar-light-header-bg};
  --#{$prefix}sidebar-nav-title-color: #{$sidebar-light-nav-title-color};
  --#{$prefix}sidebar-nav-link-color: #{$sidebar-light-nav-link-color};
  --#{$prefix}sidebar-nav-link-bg: #{$sidebar-light-nav-link-bg};
  --#{$prefix}sidebar-nav-link-icon-color: #{$sidebar-light-nav-link-icon-color};
  --#{$prefix}sidebar-nav-link-hover-color: #{$sidebar-light-nav-link-hover-color};
  --#{$prefix}sidebar-nav-link-hover-bg: #{$sidebar-light-nav-link-hover-bg};
  --#{$prefix}sidebar-nav-link-hover-icon-color: #{$sidebar-light-nav-link-hover-icon-color};
  --#{$prefix}sidebar-nav-link-active-color: #{$sidebar-light-nav-link-active-color};
  --#{$prefix}sidebar-nav-link-active-bg: #{$sidebar-light-nav-link-active-bg};
  --#{$prefix}sidebar-nav-link-active-icon-color: #{$sidebar-light-nav-link-active-icon-color};
  --#{$prefix}sidebar-nav-link-disabled-color: #{$sidebar-light-nav-link-disabled-color};
  --#{$prefix}sidebar-nav-link-disabled-icon-color: #{$sidebar-light-nav-link-disabled-icon-color};
  --#{$prefix}sidebar-nav-group-bg: #{$sidebar-light-nav-group-bg};
  --#{$prefix}sidebar-nav-group-toggle-show-color: #{$sidebar-light-nav-group-toggle-show-color};
  --#{$prefix}sidebar-nav-group-indicator: #{$sidebar-light-nav-group-indicator-icon};
  --#{$prefix}sidebar-nav-group-indicator-hover: #{$sidebar-light-nav-group-indicator-hover-icon};
  --#{$prefix}sidebar-footer-bg: #{$sidebar-light-footer-bg};
  --#{$prefix}sidebar-toggler-bg: #{$sidebar-light-toggler-bg};
  --#{$prefix}sidebar-toggler-hover-bg: #{$sidebar-light-toggler-hover-bg};
  --#{$prefix}sidebar-toggler-indicator: #{$sidebar-light-toggler-indicator-icon};
  --#{$prefix}sidebar-toggler-indicator-hover: #{$sidebar-light-toggler-indicator-hover-icon};
}
